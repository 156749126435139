/*
Template Name: Shreyu - Responsive Bootstrap 5 Admin Dashboard
Author: CoderThemes
Version: 2.0.0
Website: https://coderthemes.com/
Contact: support@coderthemes.com
File: Main Css File
*/
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300;400;500;600&display=swap");
html {
  position: relative;
  min-height: 100%; }

body {
  overflow-x: hidden; }

.panda-image {
  width: 100%;
  margin: 0;
  padding: 0;
  border-radius: 0.25rem; }

.tooltip.show {
  opacity: 1; }

#wrapper {
  height: 100%;
  overflow: hidden;
  width: 100%; }

.content-page {
  margin-left: 250px;
  overflow: hidden;
  padding: 0 15px 65px 15px;
  min-height: 80vh;
  margin-top: 70px; }

.left-side-menu {
  width: 250px;
  background: #ffffff;
  border-right: 1px solid #424e5a;
  bottom: 0;
  padding: 20px 0;
  position: fixed;
  transition: all .1s ease-out;
  top: 70px; }

.left-side-menu ul {
  margin-top: -11px; }

#sidebar-menu > ul {
  list-style: none;
  padding: 0; }
  #sidebar-menu > ul > li > a {
    color: #4b4b5a;
    display: block;
    padding: 12px 25px;
    position: relative;
    transition: all 0.4s;
    font-family: "IBM Plex Sans", sans-serif;
    font-size: 0.95rem; }
    #sidebar-menu > ul > li > a:hover, #sidebar-menu > ul > li > a:focus, #sidebar-menu > ul > li > a:active {
      color: #5369f8;
      text-decoration: none; }
      #sidebar-menu > ul > li > a:hover svg, #sidebar-menu > ul > li > a:focus svg, #sidebar-menu > ul > li > a:active svg {
        fill: rgba(83, 105, 248, 0.12); }
    #sidebar-menu > ul > li > a > span {
      vertical-align: middle; }
    #sidebar-menu > ul > li > a i {
      display: inline-block;
      line-height: 1.0625rem;
      margin: 0 10px 0 3px;
      text-align: center;
      vertical-align: middle;
      width: 16px;
      font-size: 18px; }
    #sidebar-menu > ul > li > a svg {
      width: 16px;
      height: 16px;
      margin-left: 3px;
      margin-right: 10px;
      fill: rgba(75, 75, 90, 0.12); }
    #sidebar-menu > ul > li > a .drop-arrow {
      float: right; }
      #sidebar-menu > ul > li > a .drop-arrow i {
        margin-right: 0; }
  #sidebar-menu > ul > li > a.mm-active {
    color: #5369f8; }
  #sidebar-menu > ul > li ul {
    padding-left: 34px;
    list-style: none; }
    #sidebar-menu > ul > li ul ul {
      padding-left: 20px; }

#sidebar-menu .badge {
  margin-top: 4px; }

#sidebar-menu .menu-title {
  padding: 25px 25px 0px;
  letter-spacing: .05em;
  pointer-events: none;
  cursor: default;
  font-size: 0.6875rem;
  text-transform: uppercase;
  color: #4b4b5a;
  position: relative; }
  #sidebar-menu .menu-title::before {
    height: 1px;
    position: absolute;
    top: 10px;
    bottom: 10px;
    left: 15px;
    right: 15px;
    background: #424e5a;
    content: ""; }

#sidebar-menu .menuitem-active > a {
  color: #5369f8; }
  #sidebar-menu .menuitem-active > a svg {
    fill: rgba(83, 105, 248, 0.12); }

#sidebar-menu .menuitem-active .active {
  color: #5369f8; }
  #sidebar-menu .menuitem-active .active svg {
    fill: rgba(83, 105, 248, 0.12); }

.nav-second-level li a {
  padding: 8px 25px;
  color: #4b4b5a;
  display: block;
  position: relative;
  transition: all 0.4s;
  font-size: 0.875rem; }
  .nav-second-level li a:focus, .nav-second-level li a:hover {
    color: #5369f8; }

.nav-second-level li.active > a {
  color: #5369f8; }

.menu-arrow {
  transition: transform .15s;
  position: absolute;
  right: 20px;
  display: inline-block;
  font-family: 'unicons';
  text-rendering: auto;
  line-height: 1.8rem;
  font-size: 1rem;
  transform: translate(0, 0); }
  .menu-arrow::before {
    content: "\e82f"; }

[dir="rtl"] .menu-arrow::before {
  content: "\e82e"; }

li > a[aria-expanded="true"] > span.menu-arrow {
  transform: rotate(90deg); }

li.menuitem-active > a:not(.collapsed) > span.menu-arrow {
  transform: rotate(90deg); }

body[data-sidebar-size="condensed"] .logo-box {
  width: 70px !important;
  text-align: center; }
  @media (max-width: 991.98px) {
    body[data-sidebar-size="condensed"] .logo-box {
      padding-left: 0 !important; } }

body[data-sidebar-size="condensed"] .logo span.logo-lg {
  display: none; }

body[data-sidebar-size="condensed"] .logo span.logo-sm {
  display: block; }

body[data-sidebar-size="condensed"] .left-side-menu {
  position: absolute;
  padding-top: 0;
  width: 70px !important;
  z-index: 5; }
  body[data-sidebar-size="condensed"] .left-side-menu .simplebar-mask,
  body[data-sidebar-size="condensed"] .left-side-menu .simplebar-content-wrapper {
    overflow: visible !important; }
  body[data-sidebar-size="condensed"] .left-side-menu .simplebar-scrollbar {
    display: none !important; }
  body[data-sidebar-size="condensed"] .left-side-menu .simplebar-offset {
    bottom: 0 !important; }
  body[data-sidebar-size="condensed"] .left-side-menu #sidebar-menu .menu-title,
  body[data-sidebar-size="condensed"] .left-side-menu #sidebar-menu .menu-arrow,
  body[data-sidebar-size="condensed"] .left-side-menu #sidebar-menu .label,
  body[data-sidebar-size="condensed"] .left-side-menu #sidebar-menu .badge,
  body[data-sidebar-size="condensed"] .left-side-menu #sidebar-menu .collapse.in {
    display: none !important; }
  body[data-sidebar-size="condensed"] .left-side-menu #sidebar-menu .nav.collapse {
    height: inherit !important; }
  body[data-sidebar-size="condensed"] .left-side-menu #sidebar-menu > ul > li {
    position: relative;
    white-space: nowrap; }
    body[data-sidebar-size="condensed"] .left-side-menu #sidebar-menu > ul > li > a {
      padding: 15px 20px;
      min-height: 54px;
      transition: none; }
      body[data-sidebar-size="condensed"] .left-side-menu #sidebar-menu > ul > li > a:hover, body[data-sidebar-size="condensed"] .left-side-menu #sidebar-menu > ul > li > a:active, body[data-sidebar-size="condensed"] .left-side-menu #sidebar-menu > ul > li > a:focus {
        color: #5369f8; }
      body[data-sidebar-size="condensed"] .left-side-menu #sidebar-menu > ul > li > a i {
        font-size: 1.3rem;
        margin-right: 20px;
        margin-left: 5px; }
      body[data-sidebar-size="condensed"] .left-side-menu #sidebar-menu > ul > li > a svg {
        width: 18px;
        height: 18px;
        margin-left: 6px; }
      body[data-sidebar-size="condensed"] .left-side-menu #sidebar-menu > ul > li > a span {
        display: none;
        padding-left: 25px; }
    body[data-sidebar-size="condensed"] .left-side-menu #sidebar-menu > ul > li:hover > a {
      position: relative;
      width: calc(190px + 70px);
      color: #5369f8;
      background-color: #323a46; }
      body[data-sidebar-size="condensed"] .left-side-menu #sidebar-menu > ul > li:hover > a span {
        display: inline; }
    body[data-sidebar-size="condensed"] .left-side-menu #sidebar-menu > ul > li:hover a.open :after,
    body[data-sidebar-size="condensed"] .left-side-menu #sidebar-menu > ul > li:hover a.active :after {
      display: none; }
    body[data-sidebar-size="condensed"] .left-side-menu #sidebar-menu > ul > li:hover > .collapse,
    body[data-sidebar-size="condensed"] .left-side-menu #sidebar-menu > ul > li:hover > .collapsing {
      display: block !important;
      height: auto !important;
      transition: none !important; }
      body[data-sidebar-size="condensed"] .left-side-menu #sidebar-menu > ul > li:hover > .collapse > ul,
      body[data-sidebar-size="condensed"] .left-side-menu #sidebar-menu > ul > li:hover > .collapsing > ul {
        display: block !important;
        left: 70px;
        position: absolute;
        width: 190px;
        box-shadow: 3px 5px 10px 0 rgba(154, 161, 171, 0.2); }
        body[data-sidebar-size="condensed"] .left-side-menu #sidebar-menu > ul > li:hover > .collapse > ul ul,
        body[data-sidebar-size="condensed"] .left-side-menu #sidebar-menu > ul > li:hover > .collapsing > ul ul {
          box-shadow: 3px 5px 10px 0 rgba(154, 161, 171, 0.2); }
        body[data-sidebar-size="condensed"] .left-side-menu #sidebar-menu > ul > li:hover > .collapse > ul a,
        body[data-sidebar-size="condensed"] .left-side-menu #sidebar-menu > ul > li:hover > .collapsing > ul a {
          box-shadow: none;
          padding: 8px 20px;
          position: relative;
          width: 190px;
          z-index: 6; }
          body[data-sidebar-size="condensed"] .left-side-menu #sidebar-menu > ul > li:hover > .collapse > ul a:hover,
          body[data-sidebar-size="condensed"] .left-side-menu #sidebar-menu > ul > li:hover > .collapsing > ul a:hover {
            color: #5369f8; }
  body[data-sidebar-size="condensed"] .left-side-menu #sidebar-menu > ul .collapsing {
    display: block !important;
    height: auto !important;
    transition: none !important; }
  body[data-sidebar-size="condensed"] .left-side-menu #sidebar-menu > ul ul {
    padding: 5px 0;
    z-index: 9999;
    display: none;
    background-color: #ffffff; }
    body[data-sidebar-size="condensed"] .left-side-menu #sidebar-menu > ul ul li:hover > .collapse,
    body[data-sidebar-size="condensed"] .left-side-menu #sidebar-menu > ul ul li:hover > .collapsing {
      display: block !important;
      height: auto !important;
      transition: none !important; }
      body[data-sidebar-size="condensed"] .left-side-menu #sidebar-menu > ul ul li:hover > .collapse > ul,
      body[data-sidebar-size="condensed"] .left-side-menu #sidebar-menu > ul ul li:hover > .collapsing > ul {
        display: block;
        left: 190px;
        margin-top: -36px;
        position: absolute;
        width: 190px; }
    body[data-sidebar-size="condensed"] .left-side-menu #sidebar-menu > ul ul li > a span.pull-right {
      position: absolute;
      right: 20px;
      top: 12px;
      transform: rotate(270deg); }
    body[data-sidebar-size="condensed"] .left-side-menu #sidebar-menu > ul ul li.active a {
      color: #5369f8; }

body[data-sidebar-size="condensed"] .content-page {
  margin-left: 70px !important; }

@media (min-width: 992px) {
  body[data-sidebar-size="condensed"] .footer {
    left: 70px !important; } }

body[data-sidebar-size="condensed"] .user-box {
  display: none !important; }

@media (min-width: 768px) {
  body[data-sidebar-size="condensed"]:not([data-layout="compact"]) {
    min-height: 1750px; } }

@media (max-width: 575.98px) {
  .pro-user-name {
    display: none; } }

@media (max-width: 991.98px) {
  body {
    overflow-x: hidden;
    padding-bottom: 80px; }
  .left-side-menu {
    display: none;
    z-index: 10 !important; }
  .sidebar-enable .left-side-menu {
    display: block; }
  .content-page,
  body[data-sidebar-size="condensed"] .content-page {
    margin-left: 0 !important;
    padding: 0 10px; }
  .footer {
    left: 0 !important; } }

/* =============
  Small Menu
============= */
body[data-sidebar-size="compact"]:not([data-sidebar-size="condensed"]) .logo-box {
  width: 160px !important; }

body[data-sidebar-size="compact"]:not([data-sidebar-size="condensed"]) .left-side-menu {
  width: 160px !important;
  text-align: center; }
  body[data-sidebar-size="compact"]:not([data-sidebar-size="condensed"]) .left-side-menu #sidebar-menu > ul > li > a > i {
    display: block;
    font-size: 18px;
    line-height: 24px;
    width: 100%;
    margin: 0; }
  body[data-sidebar-size="compact"]:not([data-sidebar-size="condensed"]) .left-side-menu #sidebar-menu > ul > li > a svg {
    display: block;
    margin: 0 auto 5px auto; }
  body[data-sidebar-size="compact"]:not([data-sidebar-size="condensed"]) .left-side-menu #sidebar-menu > ul ul {
    padding-left: 0; }
    body[data-sidebar-size="compact"]:not([data-sidebar-size="condensed"]) .left-side-menu #sidebar-menu > ul ul a {
      padding: 10px 20px; }
  body[data-sidebar-size="compact"]:not([data-sidebar-size="condensed"]) .left-side-menu .menu-arrow,
  body[data-sidebar-size="compact"]:not([data-sidebar-size="condensed"]) .left-side-menu .badge {
    display: none !important; }
  body[data-sidebar-size="compact"]:not([data-sidebar-size="condensed"]) .left-side-menu + .content-page {
    margin-left: 160px; }
  body[data-sidebar-size="compact"]:not([data-sidebar-size="condensed"]) .left-side-menu + .content-page .footer {
    left: 160px; }
  body[data-sidebar-size="compact"]:not([data-sidebar-size="condensed"]) .left-side-menu .menu-title {
    background-color: #323a46; }

body[data-sidebar-color="dark"] .logo-box {
  background-color: #37424c !important;
  border-right: 1px solid #37424c !important; }
  body[data-sidebar-color="dark"] .logo-box .logo-dark {
    display: none; }
  body[data-sidebar-color="dark"] .logo-box .logo-light {
    display: block; }

body[data-sidebar-color="dark"] .left-side-menu {
  background-color: #37424c;
  border-right-color: #37424c;
  box-shadow: none; }
  body[data-sidebar-color="dark"] .left-side-menu #sidebar-menu > ul > li > a {
    color: #9097a7; }
    body[data-sidebar-color="dark"] .left-side-menu #sidebar-menu > ul > li > a:hover, body[data-sidebar-color="dark"] .left-side-menu #sidebar-menu > ul > li > a:focus, body[data-sidebar-color="dark"] .left-side-menu #sidebar-menu > ul > li > a:active {
      color: #c8cddc; }
  body[data-sidebar-color="dark"] .left-side-menu #sidebar-menu > ul > li > a.mm-active {
    color: #ffffff;
    background-color: #3c4853; }
  body[data-sidebar-color="dark"] .left-side-menu #sidebar-menu .menu-title {
    color: #8c98a5; }
    body[data-sidebar-color="dark"] .left-side-menu #sidebar-menu .menu-title::before {
      background: #424e5a; }
  body[data-sidebar-color="dark"] .left-side-menu #sidebar-menu .menuitem-active > a {
    color: #ffffff; }
  body[data-sidebar-color="dark"] .left-side-menu .nav-second-level li a,
  body[data-sidebar-color="dark"] .left-side-menu .nav-thrid-level li a {
    color: #9097a7; }
    body[data-sidebar-color="dark"] .left-side-menu .nav-second-level li a:focus, body[data-sidebar-color="dark"] .left-side-menu .nav-second-level li a:hover,
    body[data-sidebar-color="dark"] .left-side-menu .nav-thrid-level li a:focus,
    body[data-sidebar-color="dark"] .left-side-menu .nav-thrid-level li a:hover {
      background-color: transparent;
      color: #c8cddc; }
  body[data-sidebar-color="dark"] .left-side-menu .nav-second-level li.active > a,
  body[data-sidebar-color="dark"] .left-side-menu .nav-thrid-level li.active > a {
    color: #ffffff; }
  body[data-sidebar-color="dark"] .left-side-menu .user-box .dropdown > a {
    color: #fff !important; }

body[data-sidebar-color="dark"][data-sidebar-size="condensed"] #wrapper .left-side-menu #sidebar-menu > ul > li:hover > a {
  background-color: #37424c;
  box-shadow: none; }

body[data-sidebar-color="dark"][data-sidebar-size="condensed"] #wrapper .left-side-menu #sidebar-menu .menuitem-active .active {
  color: #5369f8; }

body[data-sidebar-color="dark"][data-sidebar-size="compact"] #wrapper .left-side-menu .menu-title {
  background-color: rgba(255, 255, 255, 0.03); }

.user-box {
  display: none; }

.user-pro-dropdown {
  padding: 15px 5px;
  width: 90%;
  margin-left: 5%;
  margin-top: 10px; }
  .user-pro-dropdown .dropdown-item {
    border-radius: 3px; }

body[data-layout-mode="detached"] .logo-box {
  background-color: transparent !important; }

@media (min-width: 992px) {
  body[data-layout-mode="detached"] .navbar-custom .container-fluid {
    max-width: 95%; }
  body[data-layout-mode="detached"] #wrapper {
    max-width: 95%;
    margin: 0 auto; }
  body[data-layout-mode="detached"] .left-side-menu {
    margin-top: 30px;
    margin-bottom: 30px;
    border-radius: 5px;
    border: 1px solid #424e5a; }
  body[data-layout-mode="detached"] .content-page {
    padding-bottom: 30px; }
  body[data-layout-mode="detached"] .logo-box {
    background-color: transparent;
    background-image: none; } }

body[data-sidebar-showuser="true"] .user-box {
  display: block; }

.logo {
  display: block; }
  .logo span.logo-lg {
    display: block; }
  .logo span.logo-sm {
    display: none; }
  .logo .logo-lg-text-dark {
    color: #8c98a5;
    font-weight: 700;
    font-size: 22px;
    text-transform: uppercase; }
  .logo .logo-lg-text-light {
    color: #fff;
    font-weight: 700;
    font-size: 22px;
    text-transform: uppercase; }

.logo-box {
  height: 70px;
  width: 250px;
  float: left;
  transition: all .1s ease-out;
  padding: 0 24px;
  background: #3c4752;
  border-right: 1px solid #3c4752; }
  .logo-box .logo {
    line-height: 70px; }

.logo-light {
  display: block; }

.logo-dark {
  display: none; }

.navbar-custom {
  background-color: #3c4752;
  padding: 0 10px 0 0;
  position: fixed;
  left: 0;
  right: 0;
  height: 70px;
  border-bottom: 1px solid #3c4752;
  z-index: 1001;
  /* Search */ }
  .navbar-custom .topnav-menu > li {
    float: left; }
  .navbar-custom .topnav-menu .nav-link {
    padding: 0 12px;
    color: rgba(255, 255, 255, 0.6);
    min-width: 32px;
    display: block;
    line-height: 70px;
    text-align: center;
    max-height: 70px; }
    .navbar-custom .topnav-menu .nav-link svg {
      height: 20px;
      width: 20px;
      color: rgba(255, 255, 255, 0.6);
      fill: rgba(255, 255, 255, 0.12); }
    .navbar-custom .topnav-menu .nav-link:hover svg, .navbar-custom .topnav-menu .nav-link:focus svg, .navbar-custom .topnav-menu .nav-link:active svg {
      color: #5369f8;
      fill: rgba(83, 105, 248, 0.1); }
  .navbar-custom .container-fluid {
    padding: 0; }
  .navbar-custom .app-search {
    height: 70px;
    display: table;
    max-width: 180px;
    margin-right: 20px; }
    .navbar-custom .app-search .app-search-box {
      display: table-cell;
      vertical-align: middle;
      position: relative; }
      .navbar-custom .app-search .app-search-box input::placeholder {
        font-size: 0.8125rem;
        color: rgba(255, 255, 255, 0.3); }
    .navbar-custom .app-search .form-control {
      border: none;
      padding-left: 20px;
      padding-right: 0;
      color: #fff;
      background-color: rgba(255, 255, 255, 0.07);
      box-shadow: none; }
    .navbar-custom .app-search .btn {
      background-color: rgba(255, 255, 255, 0.07);
      border-color: transparent;
      color: rgba(255, 255, 255, 0.3);
      box-shadow: none !important; }
  .navbar-custom .button-menu-mobile {
    border: none;
    color: #fff;
    display: inline-block;
    height: 70px;
    line-height: 70px;
    width: 60px;
    background-color: transparent;
    border: none;
    padding: 0;
    cursor: pointer; }
  .navbar-custom .button-menu-mobile.disable-btn {
    display: none; }

/* Notification */
.noti-scroll {
  max-height: 230px; }

.notification-list {
  margin-left: 0; }
  .notification-list .noti-title {
    background-color: transparent;
    padding: 15px 20px; }
  .notification-list .noti-icon-badge {
    display: inline-block;
    position: absolute;
    top: 14px;
    right: 8px; }
  .notification-list .notify-item {
    padding: 12px 20px; }
    .notification-list .notify-item .notify-icon {
      float: left;
      height: 36px;
      width: 36px;
      line-height: 36px;
      text-align: center;
      margin-right: 10px;
      border-radius: 50%;
      color: #fff; }
    .notification-list .notify-item .notify-details {
      margin-bottom: 5px;
      overflow: hidden;
      margin-left: 45px;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #f7f7f7; }
      .notification-list .notify-item .notify-details b {
        font-weight: 500; }
      .notification-list .notify-item .notify-details small {
        display: block; }
      .notification-list .notify-item .notify-details span {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 13px; }
    .notification-list .notify-item .user-msg {
      margin-left: 45px;
      white-space: normal;
      line-height: 16px; }
  .notification-list .profile-dropdown .notify-item {
    padding: 7px 20px; }

.noti-icon {
  font-size: 21px;
  vertical-align: middle; }

.profile-dropdown {
  min-width: 170px; }
  .profile-dropdown i {
    vertical-align: middle;
    margin-right: 5px; }

.nav-user {
  padding: 0 12px !important; }
  .nav-user img {
    height: 32px;
    width: 32px; }

@media (max-width: 991.98px) {
  .logo-box {
    width: 70px !important;
    padding-right: 0 !important;
    border-bottom: 1px solid #424e5a; }
    .logo-box .logo-lg {
      display: none !important; }
    .logo-box .logo-sm {
      display: block !important; } }

@media (max-width: 600px) {
  .navbar-custom .dropdown {
    position: static; }
    .navbar-custom .dropdown .dropdown-menu {
      left: 10px !important;
      right: 10px !important; } }

body[data-topbar-color="light"] .navbar-custom {
  background-color: #3c4752 !important;
  border-bottom: 1px solid #424e5a;
  /* Search */ }
  body[data-topbar-color="light"] .navbar-custom .topnav-menu .nav-link {
    color: #8c98a5; }
    body[data-topbar-color="light"] .navbar-custom .topnav-menu .nav-link svg {
      color: #8c98a5;
      fill: rgba(140, 152, 165, 0.12); }
    body[data-topbar-color="light"] .navbar-custom .topnav-menu .nav-link:hover svg, body[data-topbar-color="light"] .navbar-custom .topnav-menu .nav-link:focus svg, body[data-topbar-color="light"] .navbar-custom .topnav-menu .nav-link:active svg {
      color: #5369f8;
      fill: rgba(83, 105, 248, 0.1); }
  body[data-topbar-color="light"] .navbar-custom .button-menu-mobile {
    color: #8c98a5; }
  body[data-topbar-color="light"] .navbar-custom .app-search input::placeholder {
    color: #8c98a5 !important; }
  body[data-topbar-color="light"] .navbar-custom .app-search .form-control {
    color: #8c98a5;
    background-color: #36404a;
    border-color: #36404a; }
  body[data-topbar-color="light"] .navbar-custom .app-search .btn {
    background-color: #36404a;
    color: #5d7186; }

body[data-topbar-color="light"] .logo-box {
  background: #ffffff;
  border-right: 1px solid #424e5a; }

body[data-topbar-color="light"] .logo-dark {
  display: block; }

body[data-topbar-color="light"] .logo-light {
  display: none; }

@media (max-width: 991.98px) {
  body[data-layout-mode="horizontal"] .navbar-toggle {
    border: 0;
    position: relative;
    padding: 0;
    margin: 0;
    cursor: pointer; }
    body[data-layout-mode="horizontal"] .navbar-toggle .lines {
      width: 25px;
      display: block;
      position: relative;
      height: 16px;
      transition: all .5s ease;
      margin-top: calc(54px / 2); }
    body[data-layout-mode="horizontal"] .navbar-toggle span {
      height: 2px;
      width: 100%;
      background-color: #dee2e6;
      display: block;
      margin-bottom: 5px;
      transition: transform .5s ease; }
    body[data-layout-mode="horizontal"] .navbar-toggle.open span {
      position: absolute; }
      body[data-layout-mode="horizontal"] .navbar-toggle.open span:first-child {
        top: 7px;
        transform: rotate(45deg); }
      body[data-layout-mode="horizontal"] .navbar-toggle.open span:nth-child(2) {
        visibility: hidden; }
      body[data-layout-mode="horizontal"] .navbar-toggle.open span:last-child {
        width: 100%;
        top: 7px;
        transform: rotate(-45deg); } }

body[data-layout-mode="horizontal"] .button-menu-mobile {
  display: none; }

body[data-layout-mode="horizontal"] .logo-box {
  width: auto;
  padding-right: 50px;
  background-color: transparent;
  padding-left: 0;
  border: none !important; }

@media (max-width: 1366px) {
  body[data-layout-mode="horizontal"] .logo-box {
    padding-left: 20px; } }

@media (max-width: 360px) {
  .navbar-custom .topnav-menu .nav-link {
    padding: 0 12px; }
  .navbar-custom .button-menu-mobile {
    width: 45px; } }

body[data-layout-mode="two-column"] .logo-box {
  display: none; }

.page-title-box {
  align-items: center;
  justify-content: space-between;
  display: flex;
  margin: 0 -27px 0;
  padding: 20px 27px; }
  .page-title-box .page-title {
    font-size: 1.05rem;
    margin: 0; }
  .page-title-box .breadcrumb {
    padding: 0; }

@media (max-width: 639px) {
  .page-title-box {
    display: block; }
    .page-title-box .page-title {
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden; }
    .page-title-box .page-title-right {
      display: block;
      padding-top: 5px; } }

.footer {
  bottom: 0;
  padding: 19px 15px 20px;
  position: absolute;
  right: 0;
  color: #8c98a5;
  left: 250px;
  border-top: 1px solid #36404a; }
  .footer .footer-links a {
    color: #8c98a5;
    margin-left: 1.5rem;
    transition: all .4s; }
    .footer .footer-links a:hover {
      color: #f3f7f9; }
    .footer .footer-links a:first-of-type {
      margin-left: 0; }

.footer-alt {
  left: 0 !important;
  text-align: center;
  background-color: transparent; }

@media (max-width: 575.98px) {
  .footer {
    left: 0 !important;
    text-align: center; } }

body[data-layout-mode="horizontal"] .footer {
  left: 0 !important; }

body[data-layout-mode="horizontal"][data-layout-width="boxed"] .footer {
  max-width: 1300px !important; }

@media (min-width: 992px) {
  body[data-layout-mode="detached"] .footer {
    position: inherit;
    margin: 0 10px; } }

.right-bar {
  background-color: #3c4752;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
  display: block;
  position: fixed;
  transition: all 200ms ease-out;
  width: 260px;
  z-index: 9999;
  float: right !important;
  right: -270px;
  top: 0;
  bottom: 0; }
  .right-bar .rightbar-title {
    background-color: #5369f8;
    padding: 27px 25px;
    color: #fff; }
  .right-bar .right-bar-toggle {
    background-color: #a0aab5;
    height: 24px;
    width: 24px;
    line-height: 27px;
    color: #fff;
    text-align: center;
    border-radius: 50%;
    margin-top: -4px; }
    .right-bar .right-bar-toggle:hover {
      background-color: #a9b2bb; }
  .right-bar .user-box {
    padding: 25px;
    text-align: center; }
    .right-bar .user-box .user-img {
      position: relative;
      height: 64px;
      width: 64px;
      margin: 0 auto 15px auto; }
      .right-bar .user-box .user-img .user-edit {
        position: absolute;
        right: -5px;
        bottom: 0px;
        height: 24px;
        width: 24px;
        background-color: #fff;
        line-height: 24px;
        border-radius: 50%;
        box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175); }
    .right-bar .user-box h5 {
      margin-bottom: 2px; }
      .right-bar .user-box h5 a {
        color: #8c98a5; }
  .right-bar .notification-item .media {
    padding: 0.75rem 1rem; }
    .right-bar .notification-item .media:hover {
      background-color: #424e5a; }
  .right-bar .notification-item .user-status {
    position: absolute;
    right: 0px;
    bottom: -4px;
    font-size: 10px; }
    .right-bar .notification-item .user-status.online {
      color: #43d39e; }
    .right-bar .notification-item .user-status.away {
      color: #ffbe0b; }
    .right-bar .notification-item .user-status.busy {
      color: #ff5c75; }

.rightbar-overlay {
  background-color: rgba(140, 152, 165, 0.2);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: none;
  z-index: 9998;
  transition: all .2s ease-out; }

.right-bar-enabled .right-bar {
  right: 0; }

.right-bar-enabled .rightbar-overlay {
  display: block; }

@media (max-width: 575.98px) {
  .right-bar {
    overflow: auto; }
    .right-bar .slimscroll-menu {
      height: auto !important; } }

body[data-layout-width="boxed"] #wrapper {
  max-width: 1300px;
  margin: 0 auto;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175); }

body[data-layout-width="boxed"] .navbar-custom {
  max-width: 1300px;
  margin: 0 auto; }

body[data-layout-width="boxed"] .footer {
  margin: 0 auto;
  max-width: calc(1300px - 250px); }

body[data-layout-width="boxed"][data-sidebar-size="condensed"] .footer {
  max-width: calc(1300px - 70px); }

body[data-layout-width="boxed"][data-sidebar-size="compact"] .footer {
  max-width: calc(1300px - 160px); }

@media (min-width: 768px) {
  body[data-layout-width="boxed"][data-sidebar-size="condensed"] .content-page {
    min-height: calc(1750px - 70px); } }

@media (min-width: 1200px) {
  body[data-layout-menu-position="scrollable"]:not([data-sidebar-size="condensed"]):not([data-sidebar-size="compact"]):not([data-layout-mode="two-column"]) {
    padding-bottom: 0; }
    body[data-layout-menu-position="scrollable"]:not([data-sidebar-size="condensed"]):not([data-sidebar-size="compact"]):not([data-layout-mode="two-column"]) #wrapper {
      display: flex; }
    body[data-layout-menu-position="scrollable"]:not([data-sidebar-size="condensed"]):not([data-sidebar-size="compact"]):not([data-layout-mode="two-column"]) .navbar-custom,
    body[data-layout-menu-position="scrollable"]:not([data-sidebar-size="condensed"]):not([data-sidebar-size="compact"]):not([data-layout-mode="two-column"]) .topnav {
      position: absolute; }
    body[data-layout-menu-position="scrollable"]:not([data-sidebar-size="condensed"]):not([data-sidebar-size="compact"]):not([data-layout-mode="two-column"]) .left-side-menu {
      position: relative;
      min-width: 250px;
      max-width: 250px;
      padding: 20px 0 calc(70px + 20px); }
    body[data-layout-menu-position="scrollable"]:not([data-sidebar-size="condensed"]):not([data-sidebar-size="compact"]):not([data-layout-mode="two-column"]) .content-page {
      margin-left: 0;
      width: 100%;
      padding-bottom: 60px; } }

@media (min-width: 1200px) {
  body[data-layout-mode="horizontal"] .container-fluid {
    max-width: 90%; }
  body[data-layout-mode="horizontal"] .navbar-custom {
    padding: 0 10px; } }

body[data-layout-mode="horizontal"] .content-page {
  margin-left: 0 !important; }

.topnav {
  background: #3c4752;
  border-bottom: 1px solid #424e5a;
  margin-top: 70px;
  padding: 0 calc(10px / 2);
  position: fixed;
  left: 0;
  right: 0;
  z-index: 100; }
  .topnav .topnav-menu {
    margin: 0;
    padding: 0; }
  .topnav .navbar-nav .nav-link {
    font-size: 0.95rem;
    position: relative;
    line-height: 22px;
    padding: calc(33px / 2) 1.1rem;
    color: #8c98a5;
    font-family: "IBM Plex Sans", sans-serif; }
    .topnav .navbar-nav .nav-link i {
      font-size: 15px;
      display: inline-block; }
    .topnav .navbar-nav .nav-link svg {
      display: inline-block;
      width: 16px;
      height: 16px;
      margin: -4px 5px 0 0;
      color: #8c98a5;
      fill: rgba(140, 152, 165, 0.12); }
    .topnav .navbar-nav .nav-link:focus, .topnav .navbar-nav .nav-link:hover {
      color: #5369f8;
      background-color: transparent; }
  .topnav .navbar-nav .nav-item .dropdown.active > a.dropdown-toggle {
    color: #5369f8; }
  .topnav .navbar-nav .nav-item.active > a {
    color: #5369f8; }
    .topnav .navbar-nav .nav-item.active > a svg {
      color: #5369f8;
      fill: rgba(83, 105, 248, 0.12); }

@media (min-width: 992px) {
  .topnav {
    height: 55px; }
    .topnav .navbar-nav .nav-item:first-of-type .nav-link {
      padding-left: 0; }
    .topnav .dropdown-item {
      padding: .5rem 1.25rem;
      min-width: 180px;
      margin: 0 .3rem;
      width: auto; }
      .topnav .dropdown-item.active {
        background-color: transparent;
        color: #5369f8; }
    .topnav .dropdown.mega-dropdown .mega-dropdown-menu {
      left: 0px;
      right: auto; }
    .topnav .dropdown .dropdown-menu {
      padding: 0.3rem 0;
      margin-top: 0;
      border-radius: 0 0 0.25rem 0.25rem; }
      .topnav .dropdown .dropdown-menu .arrow-down::after {
        right: 20px;
        transform: rotate(-135deg) translateY(-50%);
        position: absolute; }
      .topnav .dropdown .dropdown-menu .dropdown .dropdown-menu {
        position: absolute;
        top: 0 !important;
        left: 100%;
        display: none; }
    .topnav .dropdown:hover > .nav-link {
      color: #5369f8; }
      .topnav .dropdown:hover > .nav-link svg {
        color: #5369f8;
        fill: rgba(83, 105, 248, 0.12); }
    .topnav .dropdown:hover > .dropdown-menu {
      display: block; }
      .topnav .dropdown:hover > .dropdown-menu > .dropdown:hover > .dropdown-item {
        color: #5369f8; }
      .topnav .dropdown:hover > .dropdown-menu > .dropdown:hover > .dropdown-menu {
        display: block; }
  .navbar-toggle {
    display: none; }
  body[data-layout-mode="horizontal"] .content-page {
    padding: 55px 12px 65px 12px; } }

.arrow-down {
  display: inline-block; }
  .arrow-down:after {
    border-color: initial;
    border-style: solid;
    border-width: 0 0 1px 1px;
    content: "";
    height: .4em;
    display: inline-block;
    right: 5px;
    top: 50%;
    margin-left: 10px;
    transform: rotate(-45deg) translateY(-50%);
    transform-origin: top;
    transition: all .3s ease-out;
    width: .4em; }

@media (max-width: 1199.98px) {
  .topnav-menu .navbar-nav li:last-of-type .dropdown .dropdown-menu {
    right: 100%;
    left: auto; } }

@media (max-width: 991.98px) {
  .topnav {
    max-height: 360px;
    overflow-y: auto;
    padding: 0; }
    .topnav .navbar-nav .nav-link {
      padding: 0.75rem 1.1rem; }
    .topnav .dropdown .dropdown-menu {
      background-color: transparent;
      border: none;
      box-shadow: none;
      padding-left: 15px; }
    .topnav .dropdown .dropdown-item {
      position: relative;
      background-color: transparent; }
      .topnav .dropdown .dropdown-item.active, .topnav .dropdown .dropdown-item:active {
        color: #5369f8; }
    .topnav .arrow-down::after {
      right: 15px;
      position: absolute; } }

body[data-layout-mode="horizontal"][data-layout-width="boxed"] .topnav {
  max-width: 1300px;
  margin: 70px auto 0; }

body[data-layout-mode=horizontal] .logo-box {
  background-color: transparent !important; }

body[data-layout-mode="two-column"] .left-side-menu {
  width: calc(70px + 220px);
  background-color: transparent;
  box-shadow: none; }

body[data-layout-mode="two-column"] .sidebar-icon-menu {
  position: fixed;
  width: 70px;
  z-index: 500;
  top: 0;
  bottom: 0;
  padding-bottom: 20px;
  background-color: #3499ff; }
  body[data-layout-mode="two-column"] .sidebar-icon-menu .logo {
    display: block;
    width: 70px;
    height: 70px;
    line-height: 70px;
    text-align: center; }
  body[data-layout-mode="two-column"] .sidebar-icon-menu .nav {
    background-color: transparent;
    margin: 24px auto; }
    body[data-layout-mode="two-column"] .sidebar-icon-menu .nav .nav-link {
      text-align: center;
      width: 40px;
      height: 40px;
      line-height: 40px;
      margin: 12px auto;
      padding: 0px;
      border-radius: 4px; }
      body[data-layout-mode="two-column"] .sidebar-icon-menu .nav .nav-link.active {
        background-color: rgba(255, 255, 255, 0.12); }
      body[data-layout-mode="two-column"] .sidebar-icon-menu .nav .nav-link svg {
        color: #fff;
        fill: rgba(255, 255, 255, 0.12);
        height: 22px;
        width: 22px; }

body[data-layout-mode="two-column"] .sidebar-main-menu {
  display: block;
  position: fixed;
  width: 220px;
  background-color: #ffffff;
  top: 70px;
  bottom: 0;
  left: 70px;
  padding: 30px 5px;
  border-right: 1px solid #424e5a;
  transition: all .1s ease-out; }
  body[data-layout-mode="two-column"] .sidebar-main-menu .sidebar-menu-body {
    padding: 20px; }
  body[data-layout-mode="two-column"] .sidebar-main-menu .menu-title {
    color: #8c98a5 !important;
    margin: 0;
    padding: 10px 20px;
    letter-spacing: .05em;
    font-size: .7rem;
    text-transform: uppercase;
    font-weight: 600; }
  body[data-layout-mode="two-column"] .sidebar-main-menu .nav > .nav-item > .nav-link {
    color: #4b4b5a;
    font-size: 0.95rem;
    font-family: "IBM Plex Sans", sans-serif; }
  body[data-layout-mode="two-column"] .sidebar-main-menu .nav > .nav-item .menu-arrow {
    right: 10px; }
  body[data-layout-mode="two-column"] .sidebar-main-menu .nav .nav-link {
    position: relative;
    color: #4b4b5a;
    padding: 6px 15px;
    border-radius: 3px;
    margin: 3px 5px; }
    body[data-layout-mode="two-column"] .sidebar-main-menu .nav .nav-link:hover, body[data-layout-mode="two-column"] .sidebar-main-menu .nav .nav-link:focus, body[data-layout-mode="two-column"] .sidebar-main-menu .nav .nav-link.active {
      color: #5369f8; }
  body[data-layout-mode="two-column"] .sidebar-main-menu .nav .menuitem-active > a.nav-link {
    color: #5369f8;
    background-color: rgba(83, 105, 248, 0.07); }
  body[data-layout-mode="two-column"] .sidebar-main-menu .nav .menuitem-active a.active {
    color: #5369f8; }
  body[data-layout-mode="two-column"] .sidebar-main-menu #sidebar-menu .menu-arrow {
    top: 7px; }
  body[data-layout-mode="two-column"] .sidebar-main-menu .nav-second-level {
    padding-left: 15px;
    list-style: none; }

@media (min-width: 992px) {
  body[data-layout-mode="two-column"] .navbar-custom {
    left: 70px !important;
    padding-left: 0px; }
    body[data-layout-mode="two-column"] .navbar-custom .logo-box {
      width: 220px; }
      body[data-layout-mode="two-column"] .navbar-custom .logo-box .logo-sm {
        display: none; }
  body[data-layout-mode="two-column"][data-sidebar-size="condensed"] .logo-box {
    width: 0 !important; } }

body[data-layout-mode="two-column"][data-sidebar-color="light"] .logo-box {
  background-color: #ffffff; }

@media (min-width: 992px) {
  body[data-layout-mode="two-column"] .content-page {
    margin-left: calc(70px + 220px); }
  body[data-layout-mode="two-column"] .footer {
    left: calc(70px + 220px); }
  body[data-layout-mode="two-column"][data-sidebar-size="condensed"] .sidebar-main-menu {
    display: none; } }

body[data-layout-mode="two-column"] .twocolumn-menu-item {
  display: none; }

body[data-layout-mode="two-column"][data-sidebar-color="dark"] .sidebar-main-menu {
  background-color: #37424c; }
  body[data-layout-mode="two-column"][data-sidebar-color="dark"] .sidebar-main-menu .nav > .nav-item > .nav-link {
    color: #9097a7; }
  body[data-layout-mode="two-column"][data-sidebar-color="dark"] .sidebar-main-menu .nav .nav-link:hover, body[data-layout-mode="two-column"][data-sidebar-color="dark"] .sidebar-main-menu .nav .nav-link:focus, body[data-layout-mode="two-column"][data-sidebar-color="dark"] .sidebar-main-menu .nav .nav-link.active {
    color: #c8cddc; }
  body[data-layout-mode="two-column"][data-sidebar-color="dark"] .sidebar-main-menu .nav .menuitem-active > a.nav-link {
    color: #5369f8;
    background-color: rgba(83, 105, 248, 0.07); }
  body[data-layout-mode="two-column"][data-sidebar-color="dark"] .sidebar-main-menu .nav .menuitem-active a.active {
    color: #5369f8; }

body[data-layout-mode="two-column"][data-sidebar-color="brand"] .sidebar-main-menu .nav > .nav-item > .nav-link, body[data-layout-mode="two-column"][data-sidebar-color="gradient"] .sidebar-main-menu .nav > .nav-item > .nav-link {
  color: rgba(255, 255, 255, 0.7); }

body[data-layout-mode="two-column"][data-sidebar-color="brand"] .sidebar-main-menu .nav .nav-link:hover, body[data-layout-mode="two-column"][data-sidebar-color="brand"] .sidebar-main-menu .nav .nav-link:focus, body[data-layout-mode="two-column"][data-sidebar-color="brand"] .sidebar-main-menu .nav .nav-link.active, body[data-layout-mode="two-column"][data-sidebar-color="gradient"] .sidebar-main-menu .nav .nav-link:hover, body[data-layout-mode="two-column"][data-sidebar-color="gradient"] .sidebar-main-menu .nav .nav-link:focus, body[data-layout-mode="two-column"][data-sidebar-color="gradient"] .sidebar-main-menu .nav .nav-link.active {
  color: rgba(255, 255, 255, 0.9); }

body[data-layout-mode="two-column"][data-layout-width="boxed"] .navbar-custom {
  max-width: calc(1300px - 70px); }

body[data-layout-mode="two-column"][data-layout-width="boxed"] .sidebar-main-menu {
  position: absolute;
  top: 0; }

body[data-layout-mode="two-column"][data-layout-width="boxed"]:not([data-sidebar-size="condensed"]) .footer {
  max-width: calc(1300px - calc(70px + 220px)); }

.avatar-xs {
  height: 1.5rem;
  width: 1.5rem; }

.avatar-sm {
  height: 2.25rem;
  width: 2.25rem; }

.avatar {
  height: 3rem;
  width: 3rem; }

.avatar-md {
  height: 3.5rem;
  width: 3.5rem; }

.avatar-lg {
  height: 4.5rem;
  width: 4.5rem; }

.avatar-xl {
  height: 6rem;
  width: 6rem; }

.avatar-xxl {
  height: 7.5rem;
  width: 7.5rem; }

.avatar-title {
  align-items: center;
  color: #fff;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%; }

.avatar-group {
  padding-left: 12px; }
  .avatar-group .avatar-group-item {
    margin: 0 0 10px -12px;
    display: inline-block;
    border: 2px solid #fff;
    border-radius: 50%; }

.checkbox label {
  display: inline-block;
  padding-left: 8px;
  position: relative;
  font-weight: 500;
  margin-bottom: 0; }
  .checkbox label::before {
    background-color: #424e5a;
    border-radius: 3px;
    border: 2px solid #424e5a;
    content: "";
    display: inline-block;
    height: 18px;
    left: 0;
    margin-left: -18px;
    position: absolute;
    transition: 0.3s ease-in-out;
    width: 18px;
    outline: none !important;
    top: 2px; }
  .checkbox label::after {
    color: #dee2e6;
    display: inline-block;
    font-size: 11px;
    height: 18px;
    left: 0;
    margin-left: -18px;
    padding-left: 3px;
    padding-top: 2px;
    position: absolute;
    top: 0;
    width: 18px; }

.checkbox input[type="checkbox"] {
  cursor: pointer;
  opacity: 0;
  z-index: 1;
  outline: none !important; }
  .checkbox input[type="checkbox"]:disabled + label {
    opacity: 0.65; }

.checkbox input[type="checkbox"]:focus + label::before {
  outline-offset: -2px;
  outline: none; }

.checkbox input[type="checkbox"]:checked + label::after {
  content: "";
  position: absolute;
  top: 6px;
  left: 7px;
  display: table;
  width: 4px;
  height: 8px;
  border: 2px solid #dee2e6;
  border-top-width: 0;
  border-left-width: 0;
  transform: rotate(45deg); }

.checkbox input[type="checkbox"]:disabled + label::before {
  background-color: #424e5a;
  cursor: not-allowed; }

.checkbox.checkbox-circle label::before {
  border-radius: 50%; }

.checkbox.checkbox-inline {
  margin-top: 0; }

.checkbox.checkbox-single input {
  height: 18px;
  width: 18px;
  position: absolute; }

.checkbox.checkbox-single label {
  height: 18px;
  width: 18px; }
  .checkbox.checkbox-single label:before {
    margin-left: 0; }
  .checkbox.checkbox-single label:after {
    margin-left: 0; }

.checkbox-primary input[type="checkbox"]:checked + label::before {
  background-color: #5369f8;
  border-color: #5369f8; }

.checkbox-primary input[type="checkbox"]:checked + label::after {
  border-color: #fff; }

.checkbox-secondary input[type="checkbox"]:checked + label::before {
  background-color: #5d7186;
  border-color: #5d7186; }

.checkbox-secondary input[type="checkbox"]:checked + label::after {
  border-color: #fff; }

.checkbox-success input[type="checkbox"]:checked + label::before {
  background-color: #43d39e;
  border-color: #43d39e; }

.checkbox-success input[type="checkbox"]:checked + label::after {
  border-color: #fff; }

.checkbox-info input[type="checkbox"]:checked + label::before {
  background-color: #25c2e3;
  border-color: #25c2e3; }

.checkbox-info input[type="checkbox"]:checked + label::after {
  border-color: #fff; }

.checkbox-warning input[type="checkbox"]:checked + label::before {
  background-color: #ffbe0b;
  border-color: #ffbe0b; }

.checkbox-warning input[type="checkbox"]:checked + label::after {
  border-color: #fff; }

.checkbox-danger input[type="checkbox"]:checked + label::before {
  background-color: #ff5c75;
  border-color: #ff5c75; }

.checkbox-danger input[type="checkbox"]:checked + label::after {
  border-color: #fff; }

.checkbox-light input[type="checkbox"]:checked + label::before {
  background-color: #424e5a;
  border-color: #424e5a; }

.checkbox-light input[type="checkbox"]:checked + label::after {
  border-color: #fff; }

.checkbox-dark input[type="checkbox"]:checked + label::before {
  background-color: #8c98a5;
  border-color: #8c98a5; }

.checkbox-dark input[type="checkbox"]:checked + label::after {
  border-color: #fff; }

.radio label {
  display: inline-block;
  padding-left: 8px;
  position: relative;
  font-weight: 500;
  margin-bottom: 0; }
  .radio label::before {
    background-color: #fff;
    border-radius: 50%;
    border: 2px solid #cedeef;
    content: "";
    display: inline-block;
    height: 18px;
    left: 0;
    margin-left: -18px;
    position: absolute;
    transition: border 0.5s ease-in-out;
    width: 18px;
    outline: none !important; }
  .radio label::after {
    background-color: #dee2e6;
    border-radius: 50%;
    content: " ";
    display: inline-block;
    height: 10px;
    left: 6px;
    margin-left: -20px;
    position: absolute;
    top: 4px;
    transform: scale(0, 0);
    transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
    width: 10px; }

.radio input[type="radio"] {
  cursor: pointer;
  opacity: 0;
  z-index: 1;
  outline: none !important; }
  .radio input[type="radio"]:disabled + label {
    opacity: 0.65; }

.radio input[type="radio"]:focus + label::before {
  outline-offset: -2px;
  outline: 5px auto -webkit-focus-ring-color;
  outline: thin dotted; }

.radio input[type="radio"]:checked + label::after {
  transform: scale(1, 1); }

.radio input[type="radio"]:disabled + label::before {
  cursor: not-allowed; }

.radio.radio-inline {
  margin-top: 0; }

.radio.radio-single label {
  height: 17px; }

.radio-primary input[type="radio"] + label::after {
  background-color: #5369f8; }

.radio-primary input[type="radio"]:checked + label::before {
  border-color: #5369f8; }

.radio-primary input[type="radio"]:checked + label::after {
  background-color: #5369f8; }

.radio-secondary input[type="radio"] + label::after {
  background-color: #5d7186; }

.radio-secondary input[type="radio"]:checked + label::before {
  border-color: #5d7186; }

.radio-secondary input[type="radio"]:checked + label::after {
  background-color: #5d7186; }

.radio-success input[type="radio"] + label::after {
  background-color: #43d39e; }

.radio-success input[type="radio"]:checked + label::before {
  border-color: #43d39e; }

.radio-success input[type="radio"]:checked + label::after {
  background-color: #43d39e; }

.radio-info input[type="radio"] + label::after {
  background-color: #25c2e3; }

.radio-info input[type="radio"]:checked + label::before {
  border-color: #25c2e3; }

.radio-info input[type="radio"]:checked + label::after {
  background-color: #25c2e3; }

.radio-warning input[type="radio"] + label::after {
  background-color: #ffbe0b; }

.radio-warning input[type="radio"]:checked + label::before {
  border-color: #ffbe0b; }

.radio-warning input[type="radio"]:checked + label::after {
  background-color: #ffbe0b; }

.radio-danger input[type="radio"] + label::after {
  background-color: #ff5c75; }

.radio-danger input[type="radio"]:checked + label::before {
  border-color: #ff5c75; }

.radio-danger input[type="radio"]:checked + label::after {
  background-color: #ff5c75; }

.radio-light input[type="radio"] + label::after {
  background-color: #424e5a; }

.radio-light input[type="radio"]:checked + label::before {
  border-color: #424e5a; }

.radio-light input[type="radio"]:checked + label::after {
  background-color: #424e5a; }

.radio-dark input[type="radio"] + label::after {
  background-color: #8c98a5; }

.radio-dark input[type="radio"]:checked + label::before {
  border-color: #8c98a5; }

.radio-dark input[type="radio"]:checked + label::after {
  background-color: #8c98a5; }

.width-xs {
  min-width: 80px; }

.width-sm {
  min-width: 100px; }

.width-md {
  min-width: 120px; }

.width-lg {
  min-width: 140px; }

.width-xl {
  min-width: 160px; }

.font-family-primary {
  font-family: "IBM Plex Sans", sans-serif; }

.font-family-secondary {
  font-family: "IBM Plex Sans", sans-serif; }

.sp-line-1,
.sp-line-2,
.sp-line-3,
.sp-line-4 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical; }

.sp-line-1 {
  -webkit-line-clamp: 1; }

.sp-line-2 {
  -webkit-line-clamp: 2; }

.sp-line-3 {
  -webkit-line-clamp: 3; }

.sp-line-4 {
  -webkit-line-clamp: 4; }

.icon-dual {
  color: #8c98a5;
  fill: rgba(140, 152, 165, 0.12); }

.icon-dual-primary {
  color: #5369f8;
  fill: rgba(83, 105, 248, 0.16); }

.icon-dual-secondary {
  color: #5d7186;
  fill: rgba(93, 113, 134, 0.16); }

.icon-dual-success {
  color: #43d39e;
  fill: rgba(67, 211, 158, 0.16); }

.icon-dual-info {
  color: #25c2e3;
  fill: rgba(37, 194, 227, 0.16); }

.icon-dual-warning {
  color: #ffbe0b;
  fill: rgba(255, 190, 11, 0.16); }

.icon-dual-danger {
  color: #ff5c75;
  fill: rgba(255, 92, 117, 0.16); }

.icon-dual-light {
  color: #424e5a;
  fill: rgba(66, 78, 90, 0.16); }

.icon-dual-dark {
  color: #8c98a5;
  fill: rgba(140, 152, 165, 0.16); }

.icon-xs {
  height: 16px;
  width: 16px; }

.icon-sm {
  height: 24px;
  width: 24px; }

.icon-md {
  height: 32px;
  width: 32px; }

.icon-lg {
  height: 40px;
  width: 40px; }

.icon-xl {
  height: 48px;
  width: 48px; }

.icon-xxl {
  height: 60px;
  width: 60px; }

.item-hovered:hover {
  background-color: #424e5a; }

.social-list-item {
  height: 2rem;
  width: 2rem;
  line-height: calc(2rem - 2px);
  display: block;
  border: 2px solid #8c98a5;
  border-radius: 50%;
  color: #8c98a5; }

.widget-flat {
  position: relative;
  overflow: hidden; }
  .widget-flat i.widget-icon {
    font-size: 36px; }

/* Chat widget */
.conversation-list {
  list-style: none;
  height: 332px;
  padding: 0 7px; }
  .conversation-list li {
    margin-bottom: 24px; }
  .conversation-list .chat-avatar {
    float: left;
    text-align: center;
    width: 38px; }
    .conversation-list .chat-avatar img {
      border-radius: 100%;
      width: 100%; }
    .conversation-list .chat-avatar i {
      font-size: 12px;
      font-style: normal; }
  .conversation-list .ctext-wrap {
    background: #f0f2fe;
    color: #5369f8;
    border-radius: 0.25rem;
    display: inline-block;
    padding: 4px 12px;
    position: relative; }
    .conversation-list .ctext-wrap i {
      display: block;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      position: relative; }
    .conversation-list .ctext-wrap p {
      margin: 0;
      padding-top: 3px; }
    .conversation-list .ctext-wrap:after {
      right: 98%;
      top: 0;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-top-color: #f0f2fe;
      border-width: 6px;
      margin-left: -1px;
      border-right-color: #f0f2fe; }
  .conversation-list .conversation-text {
    float: left;
    font-size: 12px;
    margin-left: 12px;
    width: 70%; }
  .conversation-list .odd .chat-avatar {
    float: right !important; }
  .conversation-list .odd .conversation-text {
    float: right !important;
    margin-right: 12px;
    text-align: right;
    width: 70% !important; }
  .conversation-list .odd .ctext-wrap {
    background-color: #424e5a;
    color: #dee2e6; }
    .conversation-list .odd .ctext-wrap:after {
      border-color: transparent;
      border-left-color: #424e5a;
      border-top-color: #424e5a;
      left: 99% !important; }

/* profile / users */
.profile-widget {
  position: relative; }
  .profile-widget .card-action {
    position: absolute;
    right: 7px;
    top: 12px;
    z-index: 100; }
  .profile-widget .card-img-top {
    max-height: 220px; }
  .profile-widget .profile-info {
    margin-top: -2.7rem; }

.calendar-widget .flatpickr-calendar {
  box-shadow: none;
  border: 1px solid #424e5a !important; }

.activity-widget .activity-list {
  border-bottom: 1px solid #36404a;
  padding: 12px 0px; }
  .activity-widget .activity-list:last-child {
    border: none; }

@media print {
  .left-side-menu,
  .right-bar,
  .page-title-box,
  .navbar-custom,
  .footer {
    display: none; }
  .card-body,
  .content-page,
  .right-bar,
  .content,
  body {
    padding: 0;
    margin: 0; }
  .card {
    border: none; } }

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #36404a;
  z-index: 9999; }

#status {
  width: 40px;
  height: 40px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px; }

.spinner {
  margin: 0 auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 5px solid #424e5a;
  border-right: 5px solid #424e5a;
  border-bottom: 5px solid #424e5a;
  border-left: 5px solid #5369f8;
  transform: translateZ(0);
  animation: SpinnerAnimation 1.1s infinite linear; }

.spinner,
.spinner:after {
  border-radius: 50%;
  width: 40px;
  height: 40px; }

@keyframes SpinnerAnimation {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

body.authentication-bg {
  background-size: cover;
  background-repeat: no-repeat;
  padding-bottom: 0; }

.authentication-form .form-group .input-group-text {
  background-color: transparent; }
  .authentication-form .form-group .input-group-text svg {
    height: 20px;
    width: 20px; }

.icon-fb {
  color: #3c5a98; }

.icon-google {
  color: #e94134; }

.auth-page-sidebar {
  padding: 0;
  background-image: url("../images/auth-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  position: relative; }
  .auth-page-sidebar .overlay {
    flex: 1;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.4); }
  .auth-page-sidebar .auth-user-testimonial {
    text-align: center;
    color: #fff;
    position: absolute;
    margin: 0 auto;
    padding: 0 1.75rem;
    bottom: 3rem;
    left: 0;
    right: 0; }

.logout-checkmark {
  width: 90px;
  margin: 0 auto;
  padding: 20px 0; }
  .logout-checkmark .path {
    stroke-dasharray: 1000;
    stroke-dashoffset: 0;
    animation: dash 2s ease-in-out; }
  .logout-checkmark .spin {
    animation: spin 2s;
    transform-origin: 50% 50%; }

@keyframes dash {
  0% {
    stroke-dashoffset: 1000; }
  100% {
    stroke-dashoffset: 0; } }

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg); } }

@keyframes text {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.button-list {
  margin-left: -8px;
  margin-bottom: -12px; }
  .button-list .btn {
    margin-bottom: 12px;
    margin-left: 8px; }

.grid-structure .grid-container {
  background-color: #36404a;
  margin-bottom: 10px;
  font-size: 0.8rem;
  font-weight: 600;
  padding: 10px 20px; }

.icons-list-demo div.col-sm-6 {
  cursor: pointer;
  line-height: 45px;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden; }
  .icons-list-demo div.col-sm-6 p {
    margin-bottom: 0;
    line-height: inherit; }

.icons-list-demo i {
  text-align: center;
  vertical-align: middle;
  font-size: 22px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  margin-right: 12px;
  color: #8c98a5;
  border-radius: 3px;
  display: inline-block;
  transition: all 0.2s; }

.icons-list-demo .col-md-4 {
  border-radius: 3px;
  background-clip: padding-box;
  margin-bottom: 10px; }
  .icons-list-demo .col-md-4:hover,
  .icons-list-demo .col-md-4:hover i {
    color: #5369f8; }

.icons-list .icon-item {
  padding: 15px 0;
  color: #cedeef; }
  .icons-list .icon-item svg {
    margin-right: 10px; }
  .icons-list .icon-item span {
    line-height: 30px;
    display: inline-block;
    vertical-align: middle; }

.scrollspy-example {
  position: relative;
  height: 200px;
  margin-top: .5rem;
  overflow: auto; }

.text-error {
  color: #5369f8;
  text-shadow: rgba(83, 105, 248, 0.3) 5px 1px, rgba(83, 105, 248, 0.2) 10px 3px;
  font-size: 84px;
  line-height: 90px;
  font-family: "IBM Plex Sans", sans-serif; }

.error-text-box {
  font-size: 10rem;
  font-family: "IBM Plex Sans", sans-serif;
  min-height: 200px; }
  .error-text-box .text {
    fill: none;
    stroke-width: 6;
    stroke-linejoin: round;
    stroke-dasharray: 30 100;
    stroke-dashoffset: 0;
    animation: stroke 9s infinite linear; }
  .error-text-box .text:nth-child(5n + 1) {
    stroke: #ff5c75;
    animation-delay: -1.2s; }
  .error-text-box .text:nth-child(5n + 2) {
    stroke: #ffbe0b;
    animation-delay: -2.4s; }
  .error-text-box .text:nth-child(5n + 3) {
    stroke: #5369f8;
    animation-delay: -3.6s; }
  .error-text-box .text:nth-child(5n + 4) {
    stroke: #25c2e3;
    animation-delay: -4.8s; }
  .error-text-box .text:nth-child(5n + 5) {
    stroke: #43d39e;
    animation-delay: -6s; }

@keyframes stroke {
  100% {
    stroke-dashoffset: -400; } }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .error-text-box .text {
    fill: #ff5c75;
    stroke: #ff5c75;
    stroke-width: 6;
    stroke-dasharray: 0 0;
    stroke-dashoffset: 0;
    animation: none; } }

.email-container {
  background-color: #36404a; }
  .email-container .inbox-leftbar {
    padding: 1.25rem;
    width: 250px;
    float: left; }
  .email-container .inbox-rightbar {
    margin-left: 250px;
    border-left: 5px solid #303841;
    padding: 0 0 1.5rem 6px; }

.message-list {
  display: block;
  padding-left: 0; }
  .message-list li {
    position: relative;
    display: block;
    height: 48px;
    line-height: 48px;
    cursor: default;
    transition-duration: .3s;
    background: #36404a;
    margin-bottom: 1px;
    box-shadow: 0 0.05rem 0.01rem rgba(222, 226, 230, 0.075); }
    .message-list li a {
      color: #dee2e6; }
    .message-list li:hover {
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
      transition-duration: .05s; }
    .message-list li .col-mail {
      float: left;
      position: relative; }
    .message-list li .col-mail-1 {
      width: 320px; }
      .message-list li .col-mail-1 .star-toggle,
      .message-list li .col-mail-1 .checkbox-wrapper-mail,
      .message-list li .col-mail-1 .dot {
        display: block;
        float: left; }
      .message-list li .col-mail-1 .dot {
        border: 4px solid transparent;
        border-radius: 100px;
        margin: 22px 26px 0;
        height: 0;
        width: 0;
        line-height: 0;
        font-size: 0; }
      .message-list li .col-mail-1 .checkbox-wrapper-mail {
        margin: 15px 10px 0 20px; }
      .message-list li .col-mail-1 .star-toggle {
        color: #8c98a5;
        margin-left: 10px; }
      .message-list li .col-mail-1 .title {
        position: absolute;
        top: 0;
        left: 100px;
        right: 0;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin-bottom: 0;
        line-height: 50px; }
    .message-list li .col-mail-2 {
      position: absolute;
      top: 0;
      left: 320px;
      right: 0;
      bottom: 0; }
      .message-list li .col-mail-2 .subject,
      .message-list li .col-mail-2 .date {
        position: absolute;
        top: 0; }
      .message-list li .col-mail-2 .subject {
        left: 0;
        right: 110px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap; }
      .message-list li .col-mail-2 .date {
        right: 0;
        width: 100px;
        padding-left: 10px; }
  .message-list li.active,
  .message-list li.mail-selected {
    background: #323a46;
    transition-duration: .05s; }
  .message-list li.active,
  .message-list li.active:hover {
    box-shadow: inset 3px 0 0 #25c2e3; }
  .message-list li.unread a {
    color: #7e8b9a; }
  .message-list .checkbox-wrapper-mail {
    cursor: pointer;
    height: 20px;
    width: 20px;
    position: relative;
    display: inline-block;
    box-shadow: inset 0 0 0 2px #5d7186;
    border-radius: 3px; }
    .message-list .checkbox-wrapper-mail input {
      opacity: 0;
      cursor: pointer; }
    .message-list .checkbox-wrapper-mail input:checked ~ label {
      opacity: 1; }
    .message-list .checkbox-wrapper-mail label {
      position: absolute;
      top: 3px;
      left: 3px;
      right: 3px;
      bottom: 3px;
      cursor: pointer;
      background: #cedeef;
      opacity: 0;
      margin-bottom: 0 !important;
      transition-duration: .05s; }
    .message-list .checkbox-wrapper-mail label:active {
      background: #87949b; }

.mail-list a {
  color: #dee2e6;
  padding: 7px 10px;
  display: block; }

.reply-box {
  border: 2px solid #424e5a; }

@media (max-width: 648px) {
  .email-container .inbox-leftbar {
    width: 100%;
    float: none;
    margin-bottom: 21px;
    padding-bottom: 1.25rem; }
  .email-container .inbox-rightbar {
    margin-left: 0;
    border: 0;
    padding-left: 0; } }

@media (max-width: 520px) {
  .inbox-rightbar > .btn-group {
    margin-bottom: 10px; }
  .message-list li .col-mail-1 {
    width: 150px; }
    .message-list li .col-mail-1 .title {
      left: 80px; }
  .message-list li .col-mail-2 {
    left: 160px; }
    .message-list li .col-mail-2 .date {
      text-align: right;
      padding-right: 10px;
      padding-left: 20px; } }

.chatbox {
  position: fixed;
  right: 35px;
  bottom: 10px;
  width: 300px;
  background-color: #36404a;
  border-radius: 0.25rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  z-index: 99; }
  .chatbox .chat-input .form-control {
    padding-right: 60px; }
  .chatbox .chat-link {
    position: absolute;
    right: 4px;
    top: 10px; }

.card-pricing {
  position: relative; }
  .card-pricing .card-pricing-plan-name {
    padding-bottom: 20px; }
  .card-pricing .card-pricing-icon {
    font-size: 22px;
    background-color: rgba(83, 105, 248, 0.1);
    height: 60px;
    display: inline-block;
    width: 60px;
    line-height: 62px;
    border-radius: 50%; }
  .card-pricing .card-pricing-price {
    padding: 30px 0 0; }
    .card-pricing .card-pricing-price span {
      font-size: 40%;
      color: #8c98a5;
      letter-spacing: 2px;
      text-transform: uppercase; }
  .card-pricing .card-pricing-features {
    color: #8c98a5;
    list-style: none;
    margin: 0;
    padding: 20px 0 0 0; }
    .card-pricing .card-pricing-features li {
      padding: 10px; }

.card-pricing-recommended {
  background-color: #5369f8;
  color: #fff; }
  .card-pricing-recommended .card-pricing-icon {
    background-color: rgba(255, 255, 255, 0.1); }
  .card-pricing-recommended .card-pricing-features, .card-pricing-recommended .card-pricing-price span {
    color: rgba(255, 255, 255, 0.7); }

.post-user-comment-box {
  background-color: #323a46;
  margin: 0 -.75rem;
  padding: 1rem;
  margin-top: 20px; }

.board {
  display: block;
  white-space: nowrap;
  overflow-x: auto; }

.tasks {
  display: inline-block;
  width: 22rem;
  padding: 0 1rem 1rem 1rem;
  vertical-align: top;
  margin-bottom: 10px;
  background-color: #36404a;
  border-radius: 0.2rem; }
  .tasks.tasks:not(:last-child) {
    margin-right: 1.25rem; }
  .tasks .card {
    white-space: normal;
    margin-top: 1rem; }
  .tasks .task-header {
    background-color: #424e5a;
    padding: 1rem;
    margin: 0 -1rem;
    border-radius: 0.2rem; }

.task-list-items {
  min-height: 100px;
  position: relative; }
  .task-list-items .card {
    cursor: pointer; }
  .task-list-items:before {
    content: "No Tasks";
    position: absolute;
    line-height: 110px;
    width: 100%;
    text-align: center;
    font-weight: 600; }

.task-modal-content .form-control-light {
  background-color: #3b4651 !important;
  border-color: #3b4651 !important; }

.gantt-task-details {
  min-width: 220px; }

.task-search .search-input {
  padding-left: 32px;
  z-index: 10; }

.task-search .icon-search {
  z-index: 11;
  position: absolute;
  left: 10px;
  top: 10px; }

/************** Horizontal timeline **************/
.left-timeline .events {
  border-left: 3px solid #424e5a;
  padding-left: 35px; }
  .left-timeline .events .event-list {
    position: relative; }
    .left-timeline .events .event-list::before {
      content: "";
      position: absolute;
      left: -45px;
      top: 6px;
      height: 18px;
      width: 18px;
      background-color: #5369f8;
      border-radius: 50%;
      border: 5px solid #36404a;
      z-index: 9; }
    .left-timeline .events .event-list::after {
      content: "";
      position: absolute;
      left: -38px;
      top: 14px;
      height: 3px;
      width: 30px;
      background-color: #424e5a; }

.left-timeline .album img {
  height: 36px;
  width: auto;
  border-radius: 4px; }

.timeline {
  margin-bottom: 50px;
  position: relative; }
  .timeline:before {
    background-color: #424e5a;
    bottom: 0;
    content: "";
    left: 50%;
    position: absolute;
    top: 30px;
    width: 2px;
    z-index: 0; }
  .timeline .time-show {
    margin-bottom: 30px;
    margin-top: 30px;
    position: relative; }
  .timeline .timeline-box {
    display: block;
    position: relative;
    padding: 20px; }
  .timeline .timeline-album {
    margin-top: 12px; }
    .timeline .timeline-album a {
      display: inline-block;
      margin-right: 5px; }
    .timeline .timeline-album img {
      height: 36px;
      width: auto;
      border-radius: 3px; }

@media (min-width: 768px) {
  .timeline .time-show {
    right: 69px;
    text-align: right; }
  .timeline .timeline-box {
    margin-left: 15px; }
  .timeline .timeline-icon {
    background: #5369f8;
    border-radius: 50%;
    display: block;
    height: 20px;
    left: -24px;
    margin-top: -10px;
    position: absolute;
    text-align: center;
    top: 15%;
    width: 20px;
    border: 5px solid #36404a;
    z-index: 9; }
    .timeline .timeline-icon:before {
      content: "";
      position: absolute;
      height: 3px;
      width: 19px;
      background-color: #424e5a;
      left: 15px;
      top: 3px; }
  .timeline .timeline-desk {
    display: table-cell;
    vertical-align: top;
    width: 50%; }
  .timeline-item {
    display: flex; }
    .timeline-item:before {
      content: "";
      display: block;
      width: 50%; }
    .timeline-item.timeline-item-left:after {
      content: "";
      display: block;
      width: 50%; }
    .timeline-item.timeline-item-left .timeline-desk .album {
      float: right;
      margin-top: 20px; }
      .timeline-item.timeline-item-left .timeline-desk .album a {
        float: right;
        margin-left: 5px; }
    .timeline-item.timeline-item-left .timeline-icon {
      left: auto;
      right: -26px; }
      .timeline-item.timeline-item-left .timeline-icon:before {
        left: auto;
        right: 15px; }
    .timeline-item.timeline-item-left:before {
      display: none; }
    .timeline-item.timeline-item-left .timeline-box {
      margin-right: 15px;
      margin-left: 0;
      text-align: right; } }

@media (max-width: 575.98px) {
  .timeline .time-show {
    text-align: center;
    position: relative; }
  .timeline .event-date {
    float: left !important;
    margin-right: 1.5rem;
    margin-left: 0 !important; }
  .timeline .timeline-icon {
    display: none; } }

.file-search .search-input {
  background-color: #323a46;
  padding-left: 40px; }

.file-search .icon-search {
  z-index: 9;
  position: absolute; }

@media (max-width: 768px) {
  .recent-data {
    margin-left: 0px !important;
    padding: 0px !important; } }

/* Search */
.chat-search {
  height: 70px;
  display: table;
  width: 100%; }
  .chat-search .chat-search-box {
    display: table-cell;
    vertical-align: middle;
    position: relative; }
  .chat-search .form-control {
    padding: 0;
    box-shadow: none;
    background-color: #424e5a;
    border-color: #424e5a; }
    .chat-search .form-control::placeholder {
      color: #707e8e; }
  .chat-search .btn {
    box-shadow: none !important;
    background-color: #424e5a;
    color: #cedeef; }

.user-status {
  position: absolute;
  height: 11px;
  width: 11px;
  background-color: #5d7186;
  border: 2.5px solid #fff;
  border-radius: 50%;
  bottom: 0;
  left: 35px; }

.video-call {
  background-image: url("../images/users/chat.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 450px;
  position: relative; }
  .video-call .video-call-head img {
    height: 4rem;
    width: 4rem; }
  .video-call .video-call-action {
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    padding: 60px 0 16px;
    background: linear-gradient(to top, #000 0, rgba(0, 0, 0, 0) 90%); }

.voice-call {
  height: 450px;
  position: relative; }
  .voice-call .voice-call-head img {
    width: 6rem;
    height: 6rem; }
  .voice-call .voice-call-action {
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0; }

.conversation-list {
  list-style: none;
  padding: 0 15px; }
  .conversation-list li {
    margin-bottom: 20px; }
    .conversation-list li .conversation-actions {
      display: none; }
    .conversation-list li:hover .conversation-actions {
      display: block; }
  .conversation-list .ctext-wrap {
    background: #424e5a;
    color: #cedeef;
    border-radius: 3px;
    display: inline-block;
    padding: 7px 12px;
    position: relative; }
    .conversation-list .ctext-wrap i {
      display: block;
      font-size: 12px;
      font-style: normal;
      font-weight: bold;
      position: relative; }
    .conversation-list .ctext-wrap p {
      margin: 0;
      padding: 0; }
    .conversation-list .ctext-wrap:after {
      left: -11px;
      top: 0;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-width: 6px;
      margin-right: -1px; }
  .conversation-list .conversation-text {
    float: left;
    font-size: 13px;
    margin-left: 12px;
    width: 80%; }
  .conversation-list .odd .conversation-text {
    float: right !important;
    text-align: right !important;
    width: 70% !important; }
  .conversation-list .odd .ctext-wrap {
    background-color: #424e5a;
    color: #dee2e6; }
    .conversation-list .odd .ctext-wrap:after {
      border-color: transparent;
      right: -10px;
      left: auto; }

.apex-charts {
  min-height: 10px !important; }
  .apex-charts text {
    font-family: "IBM Plex Sans", sans-serif !important;
    font-weight: 700; }
  .apex-charts .apexcharts-canvas {
    margin: 0 auto; }

.apexcharts-tooltip-title,
.apexcharts-tooltip-text,
.apexcharts-legend-text {
  font-family: "IBM Plex Sans", sans-serif !important; }

.apexcharts-legend-series {
  font-weight: 700; }

.apexcharts-gridline {
  pointer-events: none;
  stroke: #3e4852; }

.apexcharts-legend-text {
  color: #8c98a5 !important; }

.apexcharts-yaxis text,
.apexcharts-xaxis text {
  font-family: "IBM Plex Sans", sans-serif !important;
  fill: #8c98a5;
  font-weight: 700; }

.apexcharts-point-annotations text,
.apexcharts-xaxis-annotations text,
.apexcharts-yaxis-annotations text {
  fill: #8c98a5; }

.apexcharts-radar-series polygon {
  fill: transparent;
  stroke: #424e5a; }

.apexcharts-radar-series line {
  stroke: #424e5a; }

.apexcharts-plot-series .apexcharts-datalabel {
  fill: #fff !important; }

.apexcharts-datalabels-group text {
  fill: #8c98a5 !important;
  font-family: "IBM Plex Sans", sans-serif !important; }

.scatter-images-chart .apexcharts-legend {
  overflow: hidden !important;
  min-height: 17px; }

.scatter-images-chart .apexcharts-legend-marker {
  background: none !important;
  margin-right: 7px !important; }

.scatter-images-chart .apexcharts-legend-series {
  align-items: flex-start !important; }

.apexcharts-pie-series path {
  stroke: transparent !important; }

.apexcharts-track path {
  stroke: #4d5b69; }

.apexcharts-xaxis line {
  stroke: #5d7186 !important; }

.apexcharts-toolbar {
  display: none !important; }

.calendar {
  float: left;
  margin-bottom: 0; }

.fc-view-container .table-bordered th,
.fc-view-container .table-bordered td {
  border: 1px solid #424e5a; }

.fc-view {
  margin-top: 30px; }

.none-border .modal-footer {
  border-top: none; }

.fc-toolbar {
  margin: 10px 0 5px 0; }
  .fc-toolbar h2 {
    font-size: 1.25rem;
    line-height: 1.875rem;
    text-transform: uppercase; }

.fc-day-grid-event .fc-time {
  font-weight: 500; }

th.fc-day-header {
  padding: 0.5rem 0; }

.fc-day {
  background: transparent; }

.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active,
.fc-toolbar button:focus,
.fc-toolbar button:hover,
.fc-toolbar .ui-state-hover {
  z-index: 0; }

.fc th.fc-widget-header {
  background: #424e5a;
  font-size: 13px;
  line-height: 20px;
  padding: 10px 0;
  text-transform: uppercase;
  font-weight: 500; }

.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row,
.fc-unthemed .fc-popover {
  border-color: #424e5a; }

.fc-unthemed td.fc-today,
.fc-unthemed .fc-divider {
  background: #424e5a; }

.fc-button {
  background: #424e5a;
  border: none;
  color: #dee2e6;
  text-transform: capitalize;
  box-shadow: none;
  border-radius: 3px;
  margin: 0 3px;
  padding: 6px 12px;
  height: auto; }

.fc-text-arrow {
  font-family: inherit;
  font-size: 1rem; }

.fc-state-hover,
.fc-state-highlight,
.fc-cell-overlay {
  background: #424e5a; }

.fc-state-down,
.fc-state-active,
.fc-state-disabled {
  background-color: #5369f8;
  color: #fff;
  text-shadow: none; }

.fc-unthemed .fc-today {
  background: #fff; }

.fc-event {
  border-radius: 2px;
  border: none;
  cursor: move;
  font-size: 0.8125rem;
  margin: 5px 7px;
  padding: 5px 5px;
  text-align: center; }

.external-event {
  cursor: move;
  margin: 10px 0;
  padding: 8px 10px;
  color: #fff;
  border-radius: 4px; }

.fc-basic-view td.fc-week-number span {
  padding-right: 8px; }

.fc-basic-view td.fc-day-number {
  padding-right: 8px; }

.fc-basic-view .fc-content {
  color: #fff; }

.fc-time-grid-event .fc-content {
  color: #fff; }

.fc-content-skeleton .fc-day-top .fc-day-number {
  float: right;
  height: 20px;
  width: 20px;
  text-align: center;
  line-height: 20px;
  background-color: #323a46;
  border-radius: 50%;
  margin: 5px;
  font-size: 11px; }

@media (max-width: 575.98px) {
  .fc-toolbar {
    display: block; }
    .fc-toolbar .fc-left,
    .fc-toolbar .fc-right,
    .fc-toolbar .fc-center {
      text-align: left;
      float: none;
      display: block;
      clear: both;
      margin: 10px 0; }
  .fc .fc-toolbar > * > * {
    float: none; }
  .fc-today-button {
    display: none; } }

.fc-toolbar .btn {
  padding: 0.28rem 0.8rem;
  font-size: 0.7875rem;
  border-radius: 0.25rem; }

.fc-list-item-title,
.fc-list-item-time {
  color: #fff; }

[dir="rtl"] .fc-toolbar .btn-group .btn:first-child {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-radius: 0.25rem; }

[dir="rtl"] .fc-toolbar .btn-group .btn:last-child {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-radius: 0.25rem; }

.sp-container {
  background-color: #3b454f;
  z-index: 9; }
  .sp-container button {
    padding: .25rem .5rem;
    font-size: .71094rem;
    border-radius: .2rem;
    font-weight: 400;
    color: #8c98a5; }
    .sp-container button.sp-palette-toggle {
      background-color: #424e5a; }
    .sp-container button.sp-choose {
      background-color: #43d39e;
      margin-left: 5px;
      margin-right: 0; }

.sp-palette-container {
  border-right: 1px solid #424e5a; }

.sp-input {
  background-color: #3b454f;
  border-color: #424e5a !important;
  color: #94a0ad; }
  .sp-input:focus {
    outline: none; }

[dir="rtl"] .sp-alpha {
  direction: ltr; }

[dir="rtl"] .sp-original-input-container .sp-add-on {
  border: none;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important; }

[dir="rtl"] input.spectrum.with-add-on {
  border: 1px solid #424e5a;
  border-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.sp-original-input-container .sp-add-on {
  border: none !important; }

.jqstooltip {
  box-sizing: content-box;
  width: auto !important;
  height: auto !important;
  background-color: #fff !important;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  padding: 5px 10px !important;
  border-radius: 3px;
  border-color: #fff !important; }

.jqsfield {
  color: #000 !important;
  font-size: 12px !important;
  line-height: 18px !important;
  font-family: "IBM Plex Sans", sans-serif !important;
  font-weight: 700 !important; }

.dataTables_wrapper.container-fluid {
  padding: 0; }

table.dataTable {
  border-collapse: collapse !important;
  margin-bottom: 15px !important; }
  table.dataTable tbody > tr.selected,
  table.dataTable tbody > tr > .selected {
    background-color: #5369f8; }
    table.dataTable tbody > tr.selected td,
    table.dataTable tbody > tr > .selected td {
      border-color: #5369f8; }
  table.dataTable tbody td:focus {
    outline: none !important; }
  table.dataTable tbody th.focus, table.dataTable tbody td.focus {
    outline: 2px solid #5369f8 !important;
    outline-offset: -1px;
    color: #5369f8;
    background-color: rgba(83, 105, 248, 0.15); }
  table.dataTable thead .sorting::before,
  table.dataTable thead .sorting_asc::before,
  table.dataTable thead .sorting_desc::before,
  table.dataTable thead .sorting_asc_disabled::before,
  table.dataTable thead .sorting_desc_disabled::before {
    right: 1em;
    left: auto;
    content: "\2191"; }
  table.dataTable thead .sorting::after,
  table.dataTable thead .sorting_asc::after,
  table.dataTable thead .sorting_desc::after,
  table.dataTable thead .sorting_asc_disabled::after,
  table.dataTable thead .sorting_desc_disabled::after {
    right: 0.5em;
    left: auto;
    content: "\2193"; }
  table.dataTable thead > tr > th.sorting, table.dataTable thead > tr > th.sorting_asc, table.dataTable thead > tr > th.sorting_desc {
    padding-right: 30px;
    padding-left: 0.85rem; }
  table.dataTable img {
    border-radius: 0.25rem; }

.dataTables_info {
  font-weight: 600; }

table.dataTable.dtr-inline.collapsed > tbody > tr > td.dtr-control:before {
  background-color: #424e5a !important;
  border-radius: 0.1em !important; }

table.dataTable.dtr-inline.collapsed > tbody > tr[role=row] > td:first-child:before, table.dataTable.dtr-inline.collapsed > tbody > tr[role=row] > th:first-child:before {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175); }

table.dataTable.dtr-inline.collapsed > tbody > tr.parent > td:first-child:before, table.dataTable.dtr-inline.collapsed > tbody > tr.parent > th:first-child:before {
  background-color: #c8cddc; }

table.dataTable.dtr-inline.collapsed > tbody > tr > td.dtr-control {
  padding-left: 30px !important; }

div.dt-button-info {
  background-color: #5369f8;
  border: none;
  color: #fff;
  box-shadow: none;
  border-radius: 3px;
  text-align: center;
  z-index: 21; }
  div.dt-button-info h2 {
    border-bottom: none;
    background-color: rgba(255, 255, 255, 0.2);
    color: #fff; }

@media (max-width: 575.98px) {
  li.paginate_button.previous, li.paginate_button.next {
    display: inline-block;
    font-size: 1.5rem; }
  li.paginate_button {
    display: none; }
  .dataTables_paginate ul {
    text-align: center;
    display: block;
    margin: 1.5rem 0 0 !important; }
  div.dt-buttons {
    display: inline-table;
    margin-bottom: 1.5rem; } }

.activate-select .sorting_1 {
  background-color: #424e5a; }

.dt-body-right {
  text-align: right; }

.dataTables_filter {
  text-align: right; }
  .dataTables_filter input {
    margin-left: .5em;
    margin-right: 0 !important; }

.sw-main {
  box-shadow: none;
  border: 1px solid #3e4954; }
  .sw-main .sw-toolbar {
    background: transparent;
    padding: 10px; }
  .sw-main .step-content {
    background-color: transparent !important;
    text-align: left; }

.sw-theme-default .step-content {
  background-color: transparent; }

.sw-theme-default > ul.step-anchor > li > a {
  color: #cedeef; }
  .sw-theme-default > ul.step-anchor > li > a:hover {
    color: #cedeef; }
  .sw-theme-default > ul.step-anchor > li > a:after {
    background: #5369f8; }

.sw-theme-default > ul.step-anchor > li.active > a {
  color: #5369f8 !important; }

.sw-theme-default > ul.step-anchor > li.done > a {
  color: #cedeef !important; }
  .sw-theme-default > ul.step-anchor > li.done > a:after {
    background: #43d39e; }

.sw-theme-arrows > ul.step-anchor {
  background: #424e5a;
  border-bottom-color: #424e5a; }
  .sw-theme-arrows > ul.step-anchor > li a {
    padding: 10px 0 10px 45px; }
    .sw-theme-arrows > ul.step-anchor > li a:hover {
      padding: 10px 0 10px 45px;
      isolation: inherit; }
  .sw-theme-arrows > ul.step-anchor > li:first-child a {
    padding-left: 15px; }
  .sw-theme-arrows > ul.step-anchor > li > a, .sw-theme-arrows > ul.step-anchor > li > a:hover {
    background: #424e5a;
    color: #cedeef; }
  .sw-theme-arrows > ul.step-anchor > li > a::after {
    border-left-color: #424e5a !important;
    content: " ";
    display: block;
    width: 0;
    height: 0;
    border-top: 50px solid transparent;
    border-bottom: 50px solid transparent;
    border-left: 30px solid transparent;
    border-right: 0;
    position: absolute;
    top: 50%;
    margin-top: -50px;
    left: 100%;
    z-index: 2; }
  .sw-theme-arrows > ul.step-anchor > li > a::before {
    border-left-color: #424e5a !important;
    content: " ";
    display: block;
    width: 0;
    height: 0;
    border-top: 50px solid transparent;
    border-bottom: 50px solid transparent;
    border-left: 30px solid transparent;
    border-right: 0;
    position: absolute;
    top: 50%;
    margin-top: -50px;
    margin-left: 1px;
    left: 100%;
    z-index: 1; }
  .sw-theme-arrows > ul.step-anchor > li.active > a {
    background: #5369f8 !important;
    border-color: #5369f8 !important;
    isolation: inherit; }
    .sw-theme-arrows > ul.step-anchor > li.active > a:after {
      border-left-color: #5369f8 !important;
      border-right: 0 !important; }
  .sw-theme-arrows > ul.step-anchor > li.done > a {
    background: #c9d0fd !important;
    border-color: #c9d0fd !important;
    color: #5369f8 !important; }
    .sw-theme-arrows > ul.step-anchor > li.done > a:after {
      border-left-color: #c9d0fd !important;
      border-right: 0 !important; }
    .sw-theme-arrows > ul.step-anchor > li.done > a::before {
      border-left-color: #c9d0fd !important;
      border-right: 0 !important; }

.sw-theme-circles > ul.step-anchor {
  margin-top: 30px;
  background-color: transparent; }
  .sw-theme-circles > ul.step-anchor:before {
    background-color: #424e5a; }
  .sw-theme-circles > ul.step-anchor > li {
    margin-left: 40px;
    margin-right: 0; }
    .sw-theme-circles > ul.step-anchor > li > a {
      font-size: 14px;
      width: 40px;
      height: 40px;
      padding: 12px 0;
      background-color: #424e5a;
      border-color: #36404a !important;
      box-shadow: none !important; }
      .sw-theme-circles > ul.step-anchor > li > a:hover {
        background-color: #424e5a; }
      .sw-theme-circles > ul.step-anchor > li > a small {
        bottom: -20px;
        width: 100%; }
    .sw-theme-circles > ul.step-anchor > li > a, .sw-theme-circles > ul.step-anchor > li > a:hover {
      color: #cedeef; }
      .sw-theme-circles > ul.step-anchor > li > a small, .sw-theme-circles > ul.step-anchor > li > a:hover small {
        color: #cedeef; }
    .sw-theme-circles > ul.step-anchor > li.active > a {
      background: #5369f8 !important;
      border-color: #5369f8 !important;
      color: #fff !important; }
      .sw-theme-circles > ul.step-anchor > li.active > a > small {
        color: #5369f8; }
    .sw-theme-circles > ul.step-anchor > li.done > a {
      background: #43d39e !important;
      border-color: #43d39e !important;
      color: #fff !important; }
      .sw-theme-circles > ul.step-anchor > li.done > a > small {
        color: #43d39e; }

.sw-theme-dots > ul.step-anchor {
  background-color: transparent; }
  .sw-theme-dots > ul.step-anchor:before {
    background-color: #424e5a; }
  .sw-theme-dots > ul.step-anchor > li > a:before {
    bottom: -6px;
    background: #424e5a; }
  .sw-theme-dots > ul.step-anchor > li > a:after {
    left: 44%;
    bottom: -8px;
    background: #5d7186; }
  .sw-theme-dots > ul.step-anchor > li > a, .sw-theme-dots > ul.step-anchor > li > a:hover, .sw-theme-dots > ul.step-anchor > li > a:focus {
    color: #cedeef; }
  .sw-theme-dots > ul.step-anchor > li.active > a {
    color: #5369f8; }
    .sw-theme-dots > ul.step-anchor > li.active > a:after {
      background: #5369f8; }
  .sw-theme-dots > ul.step-anchor > li.done > a {
    color: #43d39e; }
    .sw-theme-dots > ul.step-anchor > li.done > a:after {
      background: #43d39e; }

[dir="rtl"] .sw-theme-dots > ul.step-anchor > li > a:after {
  left: 40%; }

@media (max-width: 768px) {
  .sw-main > ul.step-anchor > li {
    display: block;
    width: 100%; }
  .sw-theme-arrows > ul.step-anchor > li a {
    padding: 10px 15px; }
  .sw-theme-circles > ul.step-anchor {
    margin-bottom: 10px; }
    .sw-theme-circles > ul.step-anchor::before {
      width: 0px; }
    .sw-theme-circles > ul.step-anchor > li {
      margin-bottom: 10px; }
      .sw-theme-circles > ul.step-anchor > li > a > small {
        bottom: 14px;
        left: 76px; }
  .sw-theme-dots > ul.step-anchor::before {
    left: 10px;
    right: 0;
    margin-right: 10px;
    margin-left: 0px; }
  .sw-theme-dots > ul.step-anchor > li {
    margin: 0 20px; }
    .sw-theme-dots > ul.step-anchor > li > a {
      text-align: left; }
      .sw-theme-dots > ul.step-anchor > li > a::before {
        left: -23px;
        margin-right: 10px;
        margin-left: 0px; }
      .sw-theme-dots > ul.step-anchor > li > a::after {
        top: -36px;
        left: -35px !important;
        margin-right: 10px;
        margin-left: 0px; } }

.select2-container {
  width: 100% !important; }
  .select2-container .select2-selection--single {
    border: 1px solid #424e5a;
    height: calc(1.5em + 0.9rem + 2px);
    background-color: #3b454f;
    outline: none; }
    .select2-container .select2-selection--single .select2-selection__rendered {
      line-height: 36px;
      padding-left: 12px;
      color: #94a0ad;
      text-align: left; }
    .select2-container .select2-selection--single .select2-selection__arrow {
      height: 34px;
      width: 34px;
      right: 3px;
      left: auto; }
      .select2-container .select2-selection--single .select2-selection__arrow b {
        border-color: #8c98a5 transparent transparent transparent;
        border-width: 6px 6px 0 6px; }
  .select2-container input::placeholder {
    color: #55606b; }

.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #8c98a5 transparent !important;
  border-width: 0 6px 6px 6px !important; }

.select2-results__option {
  padding: 6px 12px;
  text-align: left; }

.select2-dropdown {
  border: 0 solid #404a53;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  background-color: #3b454f; }

.select2-container--default .select2-search--dropdown {
  padding: 10px;
  background-color: #404b56; }
  .select2-container--default .select2-search--dropdown .select2-search__field {
    outline: none;
    border: 1px solid #424e5a;
    background-color: #3b454f;
    color: #94a0ad;
    text-align: left; }

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #5369f8; }

.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #404b56;
  color: #8c98a5; }
  .select2-container--default .select2-results__option[aria-selected=true]:hover {
    background-color: #5369f8;
    color: #fff; }

.select2-container .select2-selection--multiple {
  min-height: calc(1.5em + 0.9rem + 2px);
  border: 1px solid #424e5a !important;
  background-color: #3b454f; }
  .select2-container .select2-selection--multiple .select2-selection__choice__display {
    /*rtl:ignore*/
    padding-left: 0px; }
  .select2-container .select2-selection--multiple .select2-selection__rendered {
    padding: 1px 10px;
    float: left; }
  .select2-container .select2-selection--multiple .select2-search__field {
    border: 0;
    color: #94a0ad; }
  .select2-container .select2-selection--multiple .select2-selection__choice {
    background-color: #5369f8;
    border: none;
    color: #fff;
    border-radius: 3px;
    float: left; }
  .select2-container .select2-selection--multiple .select2-selection__choice__remove {
    color: #fff;
    margin-right: 2px;
    /*rtl:ignore*/
    margin-left: 0px;
    /*rtl:ignore*/
    border-right: none; }
    .select2-container .select2-selection--multiple .select2-selection__choice__remove:hover {
      color: #fff;
      background-color: #5369f8; }

.select2-container .select2-search--inline {
  float: left; }
  .select2-container .select2-search--inline .select2-search__field {
    margin-top: 7px; }

[data-simplebar] {
  position: relative;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start; }

.simplebar-wrapper {
  overflow: hidden;
  width: inherit;
  height: inherit;
  max-width: inherit;
  max-height: inherit; }

.simplebar-mask {
  direction: inherit;
  position: absolute;
  overflow: hidden;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: auto !important;
  height: auto !important;
  z-index: 0; }

.simplebar-offset {
  direction: inherit !important;
  box-sizing: inherit !important;
  resize: none !important;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
  -webkit-overflow-scrolling: touch; }

.simplebar-content-wrapper {
  direction: inherit;
  box-sizing: border-box !important;
  position: relative;
  display: block;
  height: 100%;
  /* Required for horizontal native scrollbar to not appear if parent is taller than natural height */
  width: auto;
  visibility: visible;
  overflow: auto;
  /* Scroll on this element otherwise element can't have a padding applied properly */
  max-width: 100%;
  /* Not required for horizontal scroll to trigger */
  max-height: 100%;
  /* Needed for vertical scroll to trigger */
  scrollbar-width: none; }

.simplebar-content-wrapper::-webkit-scrollbar,
.simplebar-hide-scrollbar::-webkit-scrollbar {
  display: none; }

.simplebar-content:before,
.simplebar-content:after {
  content: ' ';
  display: table; }

.simplebar-placeholder {
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  pointer-events: none; }

.simplebar-height-auto-observer-wrapper {
  box-sizing: inherit !important;
  height: 100%;
  width: 100%;
  max-width: 1px;
  position: relative;
  float: left;
  max-height: 1px;
  overflow: hidden;
  z-index: -1;
  padding: 0;
  margin: 0;
  pointer-events: none;
  flex-grow: inherit;
  flex-shrink: 0;
  flex-basis: 0; }

.simplebar-height-auto-observer {
  box-sizing: inherit;
  display: block;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 1000%;
  width: 1000%;
  min-height: 1px;
  min-width: 1px;
  overflow: hidden;
  pointer-events: none;
  z-index: -1; }

.simplebar-track {
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden; }

[data-simplebar].simplebar-dragging .simplebar-content {
  pointer-events: none;
  user-select: none;
  -webkit-user-select: none; }

[data-simplebar].simplebar-dragging .simplebar-track {
  pointer-events: all; }

.simplebar-scrollbar {
  position: absolute;
  right: 2px;
  width: 5px;
  min-height: 10px; }

.simplebar-scrollbar:before {
  position: absolute;
  content: '';
  background: #a2adb7;
  border-radius: 7px;
  left: 0;
  right: 0;
  opacity: 0;
  transition: opacity 0.2s linear; }

.simplebar-scrollbar.simplebar-visible:before {
  /* When hovered, remove all transitions from drag handle */
  opacity: 0.5;
  transition: opacity 0s linear; }

.simplebar-track.simplebar-vertical {
  top: 0;
  width: 11px; }

.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  top: 2px;
  bottom: 2px; }

.simplebar-track.simplebar-horizontal {
  left: 0;
  height: 11px; }

.simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
  height: 100%;
  left: 2px;
  right: 2px; }

.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
  right: auto;
  left: 0;
  top: 2px;
  height: 7px;
  min-height: 0;
  min-width: 10px;
  width: auto; }

/* Rtl support */
[data-simplebar-direction='rtl'] .simplebar-track.simplebar-vertical {
  right: auto;
  left: 0; }

.hs-dummy-scrollbar-size {
  direction: rtl;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  height: 500px;
  width: 500px;
  overflow-y: hidden;
  overflow-x: scroll; }

.simplebar-hide-scrollbar {
  position: fixed;
  left: 0;
  visibility: hidden;
  overflow-y: scroll;
  scrollbar-width: none; }

.custom-scroll {
  height: 100%; }

.ms-container {
  background: transparent url("../images/plugin/multiple-arrow.png") no-repeat 50% 50%;
  width: auto;
  max-width: 370px; }
  .ms-container .ms-list {
    box-shadow: none;
    border: 1px solid #424e5a; }
    .ms-container .ms-list.ms-focus {
      box-shadow: none;
      border: 1px solid #47525e; }
  .ms-container .ms-selectable {
    background-color: #3b454f; }
    .ms-container .ms-selectable li.ms-elem-selectable {
      border: none;
      padding: 5px 10px;
      color: #cedeef; }
    .ms-container .ms-selectable li.ms-hover {
      background-color: #5369f8;
      color: #fff; }
  .ms-container .ms-selection {
    background-color: #3b454f; }
    .ms-container .ms-selection li.ms-elem-selection {
      border: none;
      padding: 5px 10px;
      color: #cedeef; }
    .ms-container .ms-selection li.ms-hover {
      background-color: #5369f8;
      color: #fff; }

.ms-selectable {
  box-shadow: none;
  outline: none !important; }

.ms-optgroup-label {
  font-weight: 500;
  font-family: "IBM Plex Sans", sans-serif;
  color: #8c98a5 !important;
  font-size: 13px;
  padding-left: 5px !important; }

.bootstrap-touchspin .btn .input-group-text {
  padding: 0;
  border: none;
  background-color: transparent;
  color: inherit; }

.parsley-errors-list {
  margin: 0;
  padding: 0; }
  .parsley-errors-list > li {
    list-style: none;
    color: #ff5c75;
    margin-top: 10px;
    padding: 4px 7px 4px 28px;
    position: relative;
    display: inline-block;
    background-color: rgba(255, 92, 117, 0.2);
    border-radius: 7px; }
    .parsley-errors-list > li:before {
      content: "\ebee";
      font-family: "unicons";
      position: absolute;
      left: 8px;
      top: 4px; }
    .parsley-errors-list > li:after {
      content: "";
      border: 8px solid transparent;
      border-bottom-color: rgba(255, 92, 117, 0.2);
      position: absolute;
      left: 14px;
      top: -16px; }

.parsley-error {
  border-color: #ff5c75; }

.parsley-success {
  border-color: #43d39e; }

.ql-container {
  font-family: "IBM Plex Sans", sans-serif;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }
  .ql-container.ql-snow {
    border-color: #424e5a; }

.ql-bubble {
  border: 1px solid #424e5a;
  border-radius: 0.25rem; }

.ql-toolbar {
  font-family: "IBM Plex Sans", sans-serif !important;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem; }
  .ql-toolbar span {
    outline: none !important;
    color: #94a0ad; }
    .ql-toolbar span:hover {
      color: #5369f8 !important; }
  .ql-toolbar.ql-snow {
    border-color: #424e5a; }
    .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
      border-color: transparent; }
    .ql-toolbar.ql-snow .ql-formats {
      margin-right: 15px;
      margin-left: 0; }

.ql-snow .ql-stroke,
.ql-snow .ql-script,
.ql-snow .ql-strike svg {
  stroke: #94a0ad; }

.ql-snow .ql-fill {
  fill: #94a0ad; }

.ql-snow .ql-picker-options {
  background-color: #3b454f;
  border-color: #404a53 !important; }

.ql-editor {
  text-align: left; }

.ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
  position: absolute;
  margin-top: -9px;
  right: 0;
  left: auto;
  top: 50%;
  width: 18px; }

.ql-editor ol li:not(.ql-direction-rtl), .ql-editor ul li:not(.ql-direction-rtl) {
  padding-left: 1.5em;
  padding-right: 0; }

.ql-editor li:not(.ql-direction-rtl):before {
  margin-left: -1.5em;
  margin-right: 0.3em;
  text-align: right; }

.ql-editor ol, .ql-editor ul {
  padding-left: 1.5em;
  padding-right: 0; }

.flatpickr-calendar {
  background: #3b454f;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border: 1px solid #404a53; }
  .flatpickr-calendar.arrowTop:before {
    border-bottom-color: #4a5763; }
  .flatpickr-calendar.arrowTop:after {
    border-bottom-color: #3b454f; }
  .flatpickr-calendar.arrowBottom:before, .flatpickr-calendar.arrowBottom:after {
    border-top-color: #3b454f; }

.flatpickr-current-month {
  font-size: 110%; }

.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {
  background: #5369f8 !important;
  border-color: #5369f8; }

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
  box-shadow: -10px 0 0 #5369f8; }

.flatpickr-time input:hover,
.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time input:focus,
.flatpickr-time .flatpickr-am-pm:focus {
  background: #3b454f;
  color: #8c98a5; }

.flatpickr-months .flatpickr-month {
  height: 36px; }

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month,
.flatpickr-months .flatpickr-month {
  color: #8c98a5;
  fill: #8c98a5; }

.flatpickr-weekdays {
  background-color: #424e5a; }

span.flatpickr-weekday,
.flatpickr-day,
.flatpickr-current-month input.cur-year[disabled],
.flatpickr-current-month input.cur-year[disabled]:hover,
.flatpickr-time input,
.flatpickr-time .flatpickr-time-separator,
.flatpickr-time .flatpickr-am-pm {
  color: #94a0ad;
  fill: #94a0ad; }

.flatpickr-day.disabled, .flatpickr-day.disabled:hover, .flatpickr-day.prevMonthDay, .flatpickr-day.nextMonthDay, .flatpickr-day.notAllowed, .flatpickr-day.notAllowed.prevMonthDay, .flatpickr-day.notAllowed.nextMonthDay {
  color: #5d7186; }

.flatpickr-day.inRange, .flatpickr-day.prevMonthDay.inRange, .flatpickr-day.nextMonthDay.inRange, .flatpickr-day.today.inRange, .flatpickr-day.prevMonthDay.today.inRange, .flatpickr-day.nextMonthDay.today.inRange, .flatpickr-day:hover, .flatpickr-day.prevMonthDay:hover, .flatpickr-day.nextMonthDay:hover, .flatpickr-day:focus, .flatpickr-day.prevMonthDay:focus, .flatpickr-day.nextMonthDay:focus {
  background: #424e5a;
  border-color: #424e5a; }

.flatpickr-calendar.showTimeInput.hasTime .flatpickr-time {
  border-top: 1px solid #4a5763; }

.numInputWrapper:hover,
.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background-color: transparent;
  color: #8c98a5; }

.flatpickr-day.inRange {
  box-shadow: -5px 0 0 #424e5a, 5px 0 0 #424e5a; }

.flatpickr-day.flatpickr-disabled, .flatpickr-day.flatpickr-disabled:hover {
  color: #5d7186; }

.dropzone {
  border: 2px dashed #424e5a;
  background: #3b454f;
  border-radius: 6px;
  cursor: pointer;
  min-height: 150px;
  padding: 20px; }
  .dropzone .dz-message {
    text-align: center;
    margin: 2rem 0; }
  .dropzone.dz-started .dz-message {
    display: none; }

.gmaps,
.gmaps-panaroma {
  height: 300px;
  background: #323a46;
  border-radius: 3px; }

.gmaps-overlay {
  display: block;
  text-align: center;
  color: #fff;
  font-size: 16px;
  line-height: 40px;
  background: #5369f8;
  border-radius: 4px;
  padding: 10px 20px; }

.gmaps-overlay_arrow {
  left: 50%;
  margin-left: -16px;
  width: 0;
  height: 0;
  position: absolute; }
  .gmaps-overlay_arrow.above {
    bottom: -15px;
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
    border-top: 16px solid #5369f8; }
  .gmaps-overlay_arrow.below {
    top: -15px;
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
    border-bottom: 16px solid #5369f8; }

.jvectormap-label {
  border: none;
  background: #8c98a5;
  color: color-yiq(#8c98a5);
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 0.875rem;
  padding: 5px 8px; }
